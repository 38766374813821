/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { sharedRef } from '@vue-storefront/core';

// @ts-ignore
import { useWishlist as useWishlistComposable } from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Product, WishlistItem } from '@gemini-vsf/api-client';
import { getProductDetailsCommand } from '~/composables/useWishlist/getProductDetailCommand';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useCart } from '~/composables';

const useWishlist = (productList) => {
  const { addItem: addProductToCart, error: cartError } = useCart();
  const { showCartError } = useAddToCart();
  const { wishlist, load, removeItem, addItem, hasItemsInWishlist, updateItem } = useWishlistComposable();
  const context = useContext();
  const loadingWishlist = ref(false);

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, 'useWishlist-itemsInWishlist');
  const filterProductSkus = (products?) => {
    const productsToFilter = products || productList?.value;
    return productsToFilter
      .filter((product) => product && !product.isMoreItem)
      .reduce((acc, product) => {
        acc.push(product.sku);

        if (product?.variants) {
          product.variants.forEach((item) => {
            acc.push(item.product?.sku);
          });
        }

        return acc;
      }, []);
  };

  const pushItems = (items) => {
    loadingWishlist.value = true;
    itemsInWishlist.value = { ...itemsInWishlist.value, ...items };
    loadingWishlist.value = false;
  };

  const loadItemsFromList = async (initialProductList) => {
    loadingWishlist.value = true;
    // todo check if we can use some sort of cache here
    pushItems(await hasItemsInWishlist({ items: filterProductSkus(initialProductList) }));
    loadingWishlist.value = false;
  };

  const toggleWishlist = async (product: Product) => {
    loadingWishlist.value = true;
    await (!itemsInWishlist.value[product.sku] ? addItem({ product, quantity: 1 }) : removeItem({ product }));
    pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
    loadingWishlist.value = false;
  };
  const removeItemFromWishlist = async ({ product }) => {
    loadingWishlist.value = true;
    if (product.wishlist_uid) {
      await removeItem({ product: { ...product.product, variantGrn: product.item_grn } });
      pushItems(await hasItemsInWishlist({ items: filterProductSkus([product.product]) }));
    } else {
      await removeItem({ product });
      pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
    }
    loadingWishlist.value = false;
  };

  const addItemToCart = async (wishlistItem: WishlistItem) => {
    loadingWishlist.value = true;
    const productGrn = wishlistItem.item_grn;
    const productId = wishlistItem.product?.uid.split('::')?.[1];
    const product = await getProductDetailsCommand.execute(context, {
      filter: {
        // @ts-ignore
        uid: productId,
      },
      // @ts-ignore
    });
    await addProductToCart({ product: product as any, quantity: wishlistItem.qty });
    if (cartError.value.addItem) {
      showCartError(cartError.value.addItem);
      return;
    }
    // @ts-ignore
    await removeItemFromWishlist(wishlistItem);
    loadingWishlist.value = false;
  };

  const addItemToWishlist = async (productAndQuantity) => {
    loadingWishlist.value = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await addItem(productAndQuantity);
    loadingWishlist.value = false;
  };

  const loadWishlist = async (pageSize = 100, nextPageToken = null) => {
    loadingWishlist.value = true;
    await load({ pageSize, nextPageToken });
    loadingWishlist.value = false;
  };

  return {
    itemsInWishlist,
    loadItemsFromList,
    toggleWishlist,
    removeItemFromWishlist,
    addItemToWishlist,
    addItemToCart,
    wishlist,
    loadWishlist,
    updateItem,
    loadingWishlist,
  };
};

export default useWishlist;
