// eslint-disable-next-line import/no-extraneous-dependencies
/* eslint-disable @typescript-eslint/dot-notation */
import { Category } from '@gemini-vsf/api-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AgnosticCategoryTree, AgnosticFacet, useVSFContext } from '@vue-storefront/core';
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { categoryGetters } from '@gemini-vsf/composables';

const reduceFilters = (query, reduceFn) => (prev, curr) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const makeArray = Array.isArray(query[curr]) || !reduceFn(curr);

  return {
    ...prev,
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const useUiHelpers = () => {
  const route = useRoute();
  const router = useRouter();
  const { query } = route.value;
  const {
    $gemini: { config: geminiConfig },
  } = useVSFContext();
  const validFilters = geminiConfig?.facets?.available || [];
  const isValidFilter = (filter: string) => validFilters.includes(filter);

  const getFiltersDataFromUrl = (onlyFilters) => {
    let priceFacet = null;
    if (Object.keys(query).includes('price')) {
      priceFacet = JSON.parse(<string>query.price);
      delete query.price;
    }
    const filtersData = Object.keys(query)
      .filter((f) => (onlyFilters ? isValidFilter(f) : !isValidFilter(f)))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce(reduceFilters(query, isValidFilter), {});
    Object.keys(filtersData).forEach((f) => {
      if (filtersData?.[f] && filtersData[f].length === 0) {
        delete filtersData[f];
      }
    });
    if (priceFacet) {
      query['price'] = JSON.stringify(priceFacet);
      filtersData['price'] = [`${priceFacet?.from}`, `${priceFacet?.to}`];
    }
    return filtersData;
  };

  const getFacetsFromURL = () => ({
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 14,
    page: Number.parseInt(query.page as string, 10) || 1,
    sort: (query.sort as string) || '',
    term: query.term as string,
  });

  const changeSearchTerm = (term: string) => term;

  const getSearchTermFromUrl = () => ({
    page: Number.parseInt(query.page as string, 10) || 1,
    sort: query.sort || '',
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 10,
    term: query.term,
  });

  const getCatLink = (category: Category): string => categoryGetters.getSlug(category);

  const getAgnosticCatLink = (category: AgnosticCategoryTree): string => categoryGetters.getSlug(category);

  const changeSorting = async (sort: string) => {
    await router.push({ query: { ...query, sort } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeFilters = async (filters: any, isPriceRemove?: boolean) => {
    const filtersDataFromUrl = getFiltersDataFromUrl(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (Object.keys(filtersDataFromUrl).includes('page')) {
      delete filtersDataFromUrl.page;
    }
    if (isPriceRemove) {
      delete filtersDataFromUrl.price;
    }

    const q = {
      ...filtersDataFromUrl,
      ...filters,
    };

    // stringify q in a query string
    // const queryStr = Object.keys(q)
    //   .map((key) => `${key}=${q[key]}`)
    //   .join('&');
    // console.log('queryStr', queryStr);

    await router.push({
      query: q,
    });
  };

  const changeItemsPerPage = async (itemsPerPage: number) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        itemsPerPage,
      },
    });
  };

  const setTermForUrl = async (term: string) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined,
      },
    });
  };

  const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';

  const isFacetCheckbox = (): boolean => false;

  const getScreenSize = (): object => {
    let width;
    let height;
    if (process.client) {
      width = window.innerWidth;
      height = window.innerHeight;
    }
    return {
      vw: width,
      vh: height,
    };
  };

  return {
    getFacetsFromURL,
    getCatLink,
    getAgnosticCatLink,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    setTermForUrl,
    isFacetColor,
    isFacetCheckbox,
    getSearchTermFromUrl,
    changeSearchTerm,
    getScreenSize,
  };
};

export default useUiHelpers;
