const toggleBodyClass = (addRemoveClass, className) => {
  const el = document.body;
  if (addRemoveClass === 'addClass') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    el.classList.add(className);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    el.classList.remove(className);
  }
};

export const preventBodyScroll = (action, className) => {
  toggleBodyClass(action, className);
};
