<template>
  <div id="error" class="page-404">
    <div class="title-404">
      <h3>{{ $t('oops!') }}</h3>
    </div>

    <div class="body-404">
      <h3>{{ $t('The page you were looking for cannot be found') }}</h3>
      <p>
        {{
          $t(
            'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.'
          )
        }}
      </p>
      <p>
        {{
          $t('You can also click the following links to get you back on track!')
        }}
      </p>
    </div>
    <div class="button-404">
      <CustomButton link="/">
        {{ $t('Homepage') }}
      </CustomButton>
      <CustomButton link="/contact-us">
        {{ $t('Contact us') }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import { useRouter, useContext } from '@nuxtjs/composition-api';
import CustomButton from '~/components/CustomButton.vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default {
  name: 'ErrorPage',
  // Forcing layout change on page leave.
  layout: 'errorLayout',
  components: { CustomButton },
  props: {},

  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { fixUrlForCurrentLocale } = useCurrentLocale();
    return {
      app,
      router,
      fixUrlForCurrentLocale,
    };
  },
  head() {
    return {
      title: this.app.i18n.t('Error page'),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.app.i18n.t('Error page'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.app.i18n.t('Error page'),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.app.i18n.t('Error page'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.app.i18n.t('Error page'),
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'index, follow',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.app.i18n.t('Error page'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#error {
  margin: 0 auto;
  padding: 25px 84px 135px 84px;
  max-width: 1366px;
  @media screen and (max-width: 768px) {
    padding: 25px 24px 50px 24px;
  }

  h1 {
    font-size: var(--h1-font-size);
  }

  h4,
  h4 span,
  strong {
    font-size: var(--h4-font-size);
  }

  p {
    line-break: word;
  }

  .body-404,
  .title-404 {
    font-family: var(--font-family-primary);
    margin: 5px auto;
    h3 {
      text-transform: uppercase;
      font-family: var(--font-family-primary);
      font-size: var(--font-size--sm);
    }
    p {
      font-size: var(--font-size--xs);
    }
  }
}

.page-404 {
  text-align: center;
  .button-404 {
    width: 50%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px auto 0 auto;
  }
}
</style>
