<template>
  <div>
    <IconSprite />
    <WishlistSidebar />
    <CartSidebar />
    <LoginModal />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <TopBar class="both" :check-sticky="checkSticky" />
    <AppHeader :check-sticky="checkSticky" :simple-header="simpleHeader" />
    <div id="layout" :class="{ 'simple-header': simpleHeader }">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible> <LogoCarousel /> </LazyHydrate>
    <LazyHydrate when-visible> <AppFooter /> </LazyHydrate>
    <LazyHydrate when-visible> <Copyright /> </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  onUnmounted,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useUser } from '@gemini-vsf/composables';
import AppHeader from '~/components/AppHeader.vue';
import TopBar from '~/components/TopBar.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import Copyright from '~/components/Footer/Copyright.vue';
import Notification from '~/components/Notification.vue';
import LogoCarousel from '~/components/LogoCarousel.vue';
import WishlistSidebar from '~/components/WishlistSidebar.vue';
import AppFooter from '~/components/AppFooter.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import { useWishlist, useCart } from '~/composables';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    Copyright,
    LazyHydrate,
    TopBar,
    AppHeader,
    WishlistSidebar,
    AppFooter,
    CartSidebar,
    LoginModal,
    Notification,
    IconSprite,
    LogoCarousel,
  },

  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart } = useCart();
    const { app } = useContext();
    const { isMobile } = app.$device;
    const { loadConfiguration } = useGeminiConfiguration();
    const { loadWishlist, wishlist: apiWishlist } = useWishlist();
    const flag = ref(false);
    const checkSticky = ref(true);
    const cookiebot = process && process.browser && window && window.Cookiebot;

    const simpleHeader = computed(() => route.value?.meta?.simpleHeader);

    const checkScroll = () => {
      if (!simpleHeader.value && !isMobile) {
        const st = window.scrollY;
        checkSticky.value = st >= Number.parseInt('20', 10);
      }
    };

    const scroll = () => {
      window.addEventListener('scroll', () => {
        checkScroll();
      });
    };

    const unScroll = () => {
      window.addEventListener('scroll', () => {
        checkScroll();
      });
    };

    onMounted(() => {
      loadUser();
      loadCart();
      scroll();
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnAccept',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      loadWishlist();
      checkScroll();
    });

    onUnmounted(() => {
      unScroll();
    });

    useFetch(async () => {
      await loadConfiguration();
    });

    return {
      route,
      isAuthenticated,
      flag,
      user,
      isMobile,
      simpleHeader,
      checkSticky,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
@import '~/assets/style/globalstyle.scss';
@import '~/assets/style/variables.scss';
#layout {
  box-sizing: border-box;
  @media (max-width: $tablet-max) {
    margin: 190px auto auto;
    &.simple-header {
      margin-top: 0;
    }
  }
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
}
*:focus,
*:focus-visible {
  outline: none;
}
// Reset CSS
html {
  width: auto;
  @include for-mobile {
    overflow-x: hidden;
  }
}
body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: var(--c-link);
  &:hover {
    color: var(--c-link-hover);
  }
}
h1 {
  font-family: var(--font-family--primary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}
h2 {
  font-family: var(--font-family--primary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}
h3 {
  font-family: var(--font-family--primary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}
h4 {
  font-family: var(--font-family--primary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}
.sf-select {
  &__error-message {
    position: absolute;
    top: 50px;
    width: calc(100% - 20px);
    text-align: center;
    > span {
      font-size: var(--font-size--5xs);
      color: var(--input-error-message-color, var(--c-danger));
    }
  }
}
</style>
