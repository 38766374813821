import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';

const localeMatchesStateAndCookies = (state, appCookies) => {
  return state.getLocale() === appCookies.get(cookieNames.localeCookieName) &&
    state.getCountry() === appCookies.get(cookieNames.countryCookieName) &&
    state.getMarket() === appCookies.get(cookieNames.marketCookieName) &&
    state.getAcceptLanguage() === appCookies.get(cookieNames.languageCookieName) &&
    state.getCurrency() === appCookies.get(cookieNames.currencyCookieName);
}

export default ({ app, route }: Context) =>
  app.$vsf.$gemini.client.interceptors.request.use(async (request) => {
    const {
      $vsf: {
        $gemini: {
          //@ts-ignore
          config: { state, localesToMarkets },
        },
      },
      i18n,
      $cookies: appCookies
    } = app;
    if (process.client) {
      const localeArray = route.fullPath ? route.fullPath.split('/') : null;

      let cleanLocaleFromUrl = '';
      if (Array.isArray(localeArray) && localeArray.length > 0) {
        cleanLocaleFromUrl = localeArray[1];
        if (cleanLocaleFromUrl.includes('?')) {
          const cleanLocaleWithoutParameters = cleanLocaleFromUrl.split('?');
          if (cleanLocaleWithoutParameters && Array.isArray(cleanLocaleWithoutParameters) && cleanLocaleWithoutParameters.length > 0) {
            cleanLocaleFromUrl = cleanLocaleWithoutParameters[0];
          }
        }
      }
      if (cleanLocaleFromUrl && cleanLocaleFromUrl !== '') {
        const currentLocaleCookie = appCookies.get(cookieNames.localeCookieName);

        const currentStoreCode = cleanLocaleFromUrl ?? currentLocaleCookie;
        const localeCodeFromPath = currentStoreCode === 'it' ? 'it' : 'en';
        const languageFromCode = currentStoreCode === 'it' ? 'it-IT' : 'en-US'

        let market: string, currency = ''
        if (localesToMarkets && localesToMarkets[cleanLocaleFromUrl]) {
          [market, currency] = localesToMarkets[cleanLocaleFromUrl].split('|') ?? [null, null];
        }

        const stateAndCookiesMatch = localeMatchesStateAndCookies(state, appCookies);
        const marketMatchesUrl = appCookies.get(cookieNames.marketCookieName) === market;
        const languageMatchesUrl = appCookies.get(cookieNames.languageCookieName) === languageFromCode;

        const shouldUpdateState = !stateAndCookiesMatch || !marketMatchesUrl || i18n.getLocaleCookie() !== localeCodeFromPath || !languageMatchesUrl;

        if (shouldUpdateState) {
          state.setStore(localeCodeFromPath);
          state.setLocale(localeCodeFromPath);
          state.setCountry(localeCodeFromPath);
          state.setAcceptLanguage(languageFromCode);
          if (market && currency && market !== '' && currency !== '') {
            state.setMarket(market);
            state.setCurrency(currency);
          }
        }
      }
    }
    return request;
  });
