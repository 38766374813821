<template>
  <div>
    <style v-if="windowWidth">
      :root {
        --window_width: {
        {
          ` ${windowWidth} px `
        }
        };
      }
    </style>
    <SfMegaMenu
      :visible="visible"
      :title="$t('Search results')"
      class="search"
      @close="$emit('close')"
    >
      <transition name="sf-fade" mode="out-in">
        <div class="transition-wrapper">
          <div
            v-if="searchResults && searchResults.length > 0"
            key="results"
            class="search__wrapper-results"
          >
            <div class="categories-search-results">
              <div
                v-if="categories && categories.length > 0"
                class="categories-search-results__secondary-categories"
              >
                <span class="list-categories-title">
                  <h5>
                    {{ $t('Categories') }}
                  </h5>
                </span>
                <SfScrollable
                  class="results--desktop"
                  show-text=""
                  hide-text=""
                >
                  <div class="list-categories-content">
                    <SfLink
                      v-for="(category, index) in categories"
                      :key="index"
                      @click="handleCategoryFilter(category)"
                      link="javascript:;"
                      class="sf-link"
                      :class="{
                        'sf-link--active': categoryFilter.includes(
                          category.uid
                        ),
                      }"
                    >
                      {{ category.name }}
                    </SfLink>
                  </div>
                </SfScrollable>
              </div>
            </div>
            <SfMegaMenuColumn
              class="sf-mega-menu-column--pined-content-on-mobile search__results"
            >
              <template #title>
                <span></span>
              </template>

              <SfScrollable
                class="results--desktop scrollable-results desktop-only"
                show-text=""
                hide-text=""
              >
                <div class="results-listing">
                  <SfProductCardCustom
                    v-for="(product, index) in searchResults"
                    :key="index"
                    :product-entity="product"
                    :is-search="true"
                  />
                </div>
              </SfScrollable>
              <SfScrollable
                class="smartphone-only scrollable-results smartphone-results"
                max-content-height="65vh"
                show-text=""
              >
                <SfProductCardCustom
                  v-for="(product, index) in searchResults"
                  :key="index"
                  class="result-card"
                  :product-entity="product"
                  :is-search="true"
                />
              </SfScrollable>
            </SfMegaMenuColumn>
          </div>
          <div
            :class="`loader-wrapper ${
              loadingSearchResults ? 'shown' : 'hidden'
            }`"
          >
            <SfLoader />
          </div>
          <div
            v-if="!searchResults && !loadingSearchResults"
            key="no-results"
            class="before-results"
          >
            <div class="no-result-wrapper">
              <span class="no-result-wrapper__label">
                {{ $t('No results were found') }}
              </span>
              <SfButton
                class="before-results__button color-secondary"
                @click="$emit('close')"
              >
                {{ $t('Go back') }}
              </SfButton>
            </div>
          </div>
        </div>
      </transition>
    </SfMegaMenu>
  </div>
</template>

<script>
import {
  SfMegaMenu,
  SfLoader,
  SfScrollable,
  SfButton,
  SfLink,
} from '@storefront-ui/vue';
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import SfProductCardCustom from '~/components/SfProductCardCustom.vue';
import { productGetters } from '@gemini-vsf/composables';
import { useImage, useWindow } from '~/composables';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfLink,
    SfMegaMenu,
    SfLoader,
    SfScrollable,
    SfButton,
    SfProductCardCustom,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    loadingSearchResults: {
      type: Boolean,
      default: true,
    },
    loadingCategoryResults: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['trigger-reached', 'reset-page'],
  setup(props, { emit }) {
    const { getGeminiImage, imageSizes } = useImage();
    const { windowWidth } = useWindow();
    const { fixUrlForCurrentLocale } = useCurrentLocale();
    const scrollable = ref(null);

    const handleCategoryFilter = (category) => {
      // each category.uid has this format grn:product-list:product-list::6528f0c0c3259d92c889a7ca
      // we need to extract the last part of the string to get the category id
      const categoryId = category.uid;
      // if category is already in the filter, remove it
      if (props.categoryFilter.includes(categoryId)) {
        const index = props.categoryFilter.indexOf(categoryId);
        // eslint-disable-next-line vue/no-mutating-props
        props.categoryFilter.splice(index, 1);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.categoryFilter.push(categoryId);
      }
      emit('reset-page');

      // reset scroll
      if (scrollable.value) {
        scrollable.value.scrollTop = 0;
      }

      // console.log('categoryFilter', props.categoryFilter);
    };

    let triggerReached = false;
    const handleResultsScroll = (event) => {
      // console.log('triggerReached', triggerReached);

      if (
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        scrollable.value.scrollTop + scrollable.value.clientHeight >=
          scrollable.value.scrollHeight - scrollable.value.clientHeight * 1.5 &&
        !triggerReached
      ) {
        triggerReached = true;
        // eslint-disable-next-line vue/no-mutating-props
        emit('trigger-reached');
      }
    };

    const bindScroll = () => {
      if (document && document.querySelector('.scrollable-results')) {
        // check when scroll end is reached using scrollHeight and scrollTop
        scrollable.value = document.querySelector(
          '.scrollable-results .sf-scrollable__content'
        );
        scrollable.value.addEventListener('scroll', handleResultsScroll);
      }
    };

    const unbindScroll = () => {
      if (document && document.querySelector('.scrollable-results')) {
        scrollable.value.removeEventListener('scroll', handleResultsScroll);
      }
    };

    const scrollHeight = computed(() =>
      scrollable.value ? scrollable.value.scrollHeight : 0
    );

    // watch props.searchResults
    watch(
      () => props.searchResults,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue.length > 0) {
          triggerReached = false;
          unbindScroll();
          // wait 1 second for the new items to be rendered
          setTimeout(() => {
            bindScroll();
          }, 1000);
        }
      }
    );

    return {
      handleCategoryFilter,
      productGetters,
      imageSizes,
      windowWidth,
      getGeminiImage,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss">
.search {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  min-height: 100vh;
  --mega-menu-column-header-margin: var(--spacer-sm) 0 var(--spacer-xl);
  --mega-menu-content-padding: 0;
  --mega-menu-height: auto;
  @include for-desktop {
    --mega-menu-content-padding: var(--spacer-xl) 0;
  }

  .sf-mega-menu__bar {
    position: static;
  }

  .sf-mega-menu__menu {
    display: flex;
    justify-content: center;
    position: static;
  }

  .sf-mega-menu__content {
    padding: 0;
    margin: 0;
    //width: 95%;
    max-width: initial;
  }

  &__wrapper-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-desktop {
      flex-direction: row;
      flex: 1;
    }
    width: 100%;

    .categories-search-results {
      //width: 100%;
      //position: absolute;
      //left: 20px;
      //top: 20px;
      .sf-scrollable__content {
        padding-right: 50px;
      }

      &__secondary-categories {
        padding: 0 10px 15px 15px;
        margin: 0 20px 20px 20px;
        display: grid;
        width: 300px;

        @include to-tablet-max {
          width: var(--window_width);
          overflow-x: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
          display: flex;
          margin: 0;
          padding: 0;
          border-radius: 0px;
        }

        .list-categories-title {
          h5 {
            font-size: 20px;
            color: black;
            margin: 15px 0 0 0;
            padding: 0;
          }

          @include to-tablet-max {
            margin-top: 55px;
            margin-right: 10px;
          }

          @include for-mobile {
            margin-top: 0;
          }
        }
        @include for-mobile {
          display: block;
        }
        .list-categories-content {
          display: block;
          max-width: 285px;
          @include to-tablet-max {
            white-space: nowrap;
            gap: 5px;
          }

          .sf-link {
            display: inline-block;
            background-color: #d41025;
            border: 2px solid #d41025;
            border-radius: 25px;
            padding: 10px;
            font-size: 14px;
            font-family: var(--font-family--primary);
            font-weight: var(--font-weight--normal);
            text-decoration: none;
            color: white;
            text-transform: lowercase;
            white-space: nowrap;
            width: fit-content;
            margin: 5px 2px;

            &--active {
              background-color: white;
              color: #d41025;
            }
          }
        }

        div {
          display: grid;
          margin-top: 15px;
          @include to-tablet-max {
            display: flex;
          }

          @include for-mobile {
            margin: 0;
          }
          span {
            margin: 15px 0;

            .sf-link {
              background-color: #d41025;
              border: 2px solid #d41025;
              border-radius: 25px;
              padding: 10px;
              font-size: 16px;
              font-family: var(--font-family--primary);
              font-weight: var(--font-weight--normal);
              text-decoration: none;
              color: white;
              text-transform: lowercase;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &__results {
    flex: 1;
  }

  &__header {
    margin-left: var(--spacer-sm);
  }

  ::v-deep .sf-bar {
    display: none;
  }

  .search__header {
    display: none;
  }

  ::v-deep .sf-mega-menu-column__header {
    display: none;
    @include for-desktop {
      display: flex;
    }
  }

  .sf-mega-menu-column__content {
    padding: 0;
    width: 100%;
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.7);

    // add some smooth animation to shown and hide the loader
    &.shown {
      opacity: 1;
      transition: opacity 0.3s;
    }
    &.hidden {
      opacity: 0;
      transition: opacity 0.3s;
      width: 0;
      height: 0;
    }

    .sf-loader {
      margin: 20% auto;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .transition-wrapper {
    width: 100%;
  }
}

.results {
  &--desktop {
    --scrollable-max-height: 75vh;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &--mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: var(--c-white);
    padding: var(--spacer-base) var(--spacer-sm);
    --product-card-max-width: 9rem;
  }
}

.see-all {
  padding: var(--spacer-xl) 0 0 var(--spacer-sm);
}

.action-buttons {
  padding: var(--spacer-xl) var(--spacer-sm) var(--spacer-3xl);
  background: var(--c-white);
  width: 100%;

  &__button {
    width: calc(100% - 32px);
  }
}

.results-listing {
  display: grid;
  padding: 20px 20px 80px 20px;

  .product-card {
    @media (min-width: $desktop-xl-min) and (max-width: 1700px),
      (min-width: $desktop-min) and (max-width: 1280px),
      (max-width: 390px) {
      width: auto;
      .product-card-image .sf-image--wrapper {
        padding: 0;
      }
    }

    .product-card-top {
      .product-card-add-to-cart-button {
        button {
          padding: 10px;
          margin: 0 auto;
          display: flex;

          span {
            white-space: initial;
            display: flex;
          }
        }
      }
    }
  }

  @include to-desktop-max {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  overflow-x: hidden;
}

.smartphone-results {
  .sf-scrollable__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $tablet-min) and (max-width: to-desktop-max) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $tablet-min - 1) {
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      .product-card-image .sf-image--wrapper .sf-image.sf-image-loaded {
        max-height: 17.5rem;
      }
    }
  }

  .product-card {
    .product-card-top {
      //display: none;
    }

    .product-card-bottom {
      //display: none;
    }
  }
}

@include to-mobile-max {
  .smartphone-results {
    .sf-scrollable__content {
      .result-card {
        max-height: 375px;

        .product-card-bottom {
          height: fit-content;

          & > a {
            height: fit-content;

            .product-card-details {
              margin-top: 0;

              .product-card-brand {
                margin: 0;
              }

              .product-card-sku-description {
                .product-card-description {
                  //display: none;
                }
              }
            }
          }

          .product-card-price-container {
            height: fit-content;
          }
        }
      }
    }
  }
}

.smartphone-results {
  margin-top: 20px;
}

.results--mobile {
  justify-content: center;
}

.action-buttons.smartphone-only {
  padding: 15px 0;

  button {
    margin: 0 auto;
  }
}

.result-card {
  margin: 0 auto;
}

.before-results {
  box-sizing: border-box;
  padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
  text-align: center;
  height: 50vh;
  @include for-desktop {
    padding: 0;
  }

  &__picture {
    --image-width: 230px;
    margin-top: var(--spacer-2xl);
    @include for-desktop {
      --image-width: 18.75rem;
      margin-top: var(--spacer-base);
    }
  }

  &__paragraph {
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight--normal);
    font-size: var(--font-size--base);
    color: var(--c-text-muted);
    margin: 0;
    @include for-desktop {
      font-size: var(--font-size--2xs);
    }

    &:first-of-type {
      margin: var(--spacer-xl) auto var(--spacer-xs);
    }
  }

  &__button {
    width: 100%;
  }

  .no-result-wrapper {
    width: 100%;
    height: 50%;
    margin-top: 10%;
    justify-content: center;
    display: grid;

    &__label {
      font-size: 16px;
      margin-bottom: 20%;
    }
  }
}
</style>
