<template>
  <div class="sf-action-input">
    <CustomInput
      class="sf-action-input__input"
      :type="type"
      :value="value"
      v-bind="$attrs"
      :placeholder="$t(placeholder)"
      v-on="listeners"
    />
    <slot name="searchButton">
      <CustomButton
        class="sf-action-input__button"
        type="button"
        :icon="icon"
        :icon-color="iconColor"
        :hover-icon-color="hoverIconColor"
        :aria-label="$t(buttonText)"
        @click="$emit('actionClick')"
      >
        {{ $t(buttonText) }}
      </CustomButton>
    </slot>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import CustomButton from '~/components/CustomButton.vue';
import CustomInput from '~/components/CustomInput.vue';

export default defineComponent({
  name: 'ActionInput',
  components: {
    CustomInput,
    CustomButton,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: [Object, String],
      default: () => ({}),
    },
    buttonText: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    hoverIconColor: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'search',
    },
  },
  setup(props) {},
  computed: {
    listeners() {
      return {
        // eslint-disable-next-line vue/no-deprecated-dollar-listeners-api
        ...this.$listeners,
        // eslint-disable-next-line vue/custom-event-name-casing
        'keyup.enter': (event) => this.$emit('keyup.enter', event.target.value),
        // eslint-disable-next-line vue/custom-event-name-casing
        'keyup.esc': () => this.$emit('keyup.esc', ''),
      };
    },
  },
});
</script>

<style lang="scss">
.sf-action-input {
  border: none;
  border-radius: 32px;
  height: 50px;
  box-shadow: 0 3px 15px var(--c-grey-7);
  display: flex;
  justify-content: space-between;
  background-color: var(--c-white);

  .sf-action-input__input {
    width: 100%;
    border: none;
    font-size: var(--font-size--3xs);
    color: var(--c-grey-2);
    box-shadow: none;

    .sf-input.sf-custom-input__input {
      border: none;
      box-shadow: none;
      &:hover,
      &:focus-within {
        border: inherit;
      }

      .sf-input__wrapper {
        & > input:focus-visible {
          outline: none;
        }

        & > input::-webkit-search-decoration,
        & > input::-webkit-search-cancel-button,
        & > input::-webkit-search-results-button,
        & > input::-webkit-search-results-decoration {
          -webkit-appearance: none;
        }
      }
    }
  }

  .custom-button.sf-action-input__button {
    padding: 15px 10%;
    border-radius: 0 32px 32px 0;
    z-index: 1;
    height: 100%;
    &:hover {
      background: var(--c-primary-darker);
    }
  }
}
@media (max-width: $tablet-max) {
  .sf-action-input {
    .custom-button {
      padding: 15px 6%;
    }
  }
}
</style>
