<template>
  <div class="search-box-container">
    <input type="text" id="search-box" />
    <div id="search-results" />
    <CustomButton
      class="sf-action-input__button sf-action-input__button__search"
      icon="search"
      icon-color="var(--c-white);"
    />
  </div>
</template>

<script>
import { focus } from '@storefront-ui/vue/src/utilities/directives';
import CustomButton from '~/components/CustomButton.vue';
import { clickOutside } from '~/composables/directives/click-outside/click-outside-directive';

export default {
  name: 'SearchInput',
  components: { CustomButton },
  directives: {
    focus,
    clickOutside,
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box-container {
  border: none;
  border-radius: 32px;
  height: 50px;
  box-shadow: 0 3px 15px var(--c-grey-7);
  display: flex;
  justify-content: space-between;
  background-color: var(--c-white);
  .sf-action-input__button__search {
    pointer-events: none;
  }

  #search-box {
    width: 100%;
    border: none;
    font-size: var(--font-size--3xs);
    color: var(--c-grey-2);
    box-shadow: none;
    border-radius: 32px;
    padding-left: 1rem;
  }

  #search-results {
    position: fixed;
    left: 0;
    top: 269px;
    width: 100vw;
  }
}

@media (max-width: $tablet-max) {
  .sf-search-bar__input {
    background-color: var(--c-white);

    .sf-action-input__input {
      .sf-input__wrapper {
        #search-box {
          font-size: var(--font-size--4xs);
        }

        & > input:focus-visible {
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-max) and (-webkit-min-device-pixel-ratio: 2) {
  .sf-search-bar__input {
    .custom-button {
      position: fixed;
      height: inherit;
      right: 10px;
    }
  }
}
</style>
