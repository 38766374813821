<template>
  <SfList class="sf-list-footer-custom">
    <div v-if="isAccount">
      <SfListItem v-for="item in accountItems" :key="item">
        <div class="link-wrap">
          <span class="link-item" @click="handleAccountLinks(item)">{{
            item
          }}</span>
        </div>
      </SfListItem>
    </div>
    <div v-else>
      <SfListItem v-for="(item, index) in items" :key="item.label + index">
        <div class="link-wrap">
          <nuxt-link
            v-if="!item.url.includes('http')"
            :target="item.target"
            :to="{ path: cleanPath(item.url, locale) }"
            class="link-item"
          >
            {{ item.label }}
          </nuxt-link>
          <a
            v-else-if="item.url.includes('http')"
            :href="item.url"
            :target="item.target"
            class="link-item"
          >
            {{ item.label }}
          </a>
        </div>
      </SfListItem>
    </div>
  </SfList>
</template>

<script>
import { SfList } from '@storefront-ui/vue';
import { useI18n } from '@/helpers/hooks/usei18n';
import { useUser } from '@gemini-vsf/composables';
import { useUiState } from '~/composables';
import { useRouter } from '@nuxtjs/composition-api';

export default {
  name: 'FooterColumnCustom',
  components: {
    SfList,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const translate = useI18n();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { toggleCartSidebar, toggleLoginModal, toggleWishlistSidebar } =
      useUiState();
    const { locale } = translate;

    const accountItems = ['Login', 'Carrello', 'Wishlist', 'I miei ordini'];
    const handleAccountLinks = (item) => {
      switch (item) {
        case 'Login': {
          toggleLoginModal();
          break;
        }
        case 'Carrello': {
          toggleCartSidebar();
          break;
        }
        case 'Wishlist': {
          toggleWishlistSidebar();
          break;
        }
        case 'I miei ordini': {
          if (isAuthenticated.value) {
            router.push('my-account/cronologia-ordini');
          } else {
            toggleLoginModal();
          }
          break;
        }
        default: {
          toggleLoginModal();
        }
      }
    };

    return { locale, accountItems, handleAccountLinks };
  },
  methods: {
    cleanPath(urlPath, locale) {
      let path = '';
      if (urlPath === '/') {
        path = `${urlPath}${locale}`;
      } else if (urlPath !== '/' && urlPath.charAt(0) === '/') {
        path = urlPath.includes(locale) ? urlPath : `/${locale}${urlPath}`;
      }
      return path;
    },
  },
};
</script>

<style lang="scss">
.sf-list.sf-list-footer-custom {
  .sf-list__item {
    margin: 0 0 5px 0;
    .link-wrap {
      .link-item {
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--xs);
        color: var(--c-grey-3);

        &:hover,
        &:focus {
          text-decoration: underline;
          color: var(--c-primary);
          @include pointer;
        }
      }
    }
  }
}

//mobile
@media (max-width: $tablet-max) {
}
//desktop
@media (min-width: $desktop-min) {
}
</style>
