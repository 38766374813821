<template>
  <small class="copyright">
    <div class="copyright-content" v-if="blockData">
      {{ $t(blockData) }}
    </div>
  </small>
</template>

<script>
import { computed, ref, useFetch } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '@/helpers/hooks/usei18n';

export default {
  name: 'Copyright',

  setup() {
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`cmsBlockCopyright-${locale}`);
    const copyrightSelector = `copyright-${locale}`;
    const blockData = ref('');

    useFetch(async () => {
      await loadBlocks({
        identifiers: [copyrightSelector],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        blockData.value = blocksContent[copyrightSelector]?.content;
      } catch {
        console.warn('Could not load Copyright block data.');
      }
    });

    return {
      blocks,
      copyrightSelector,
      blockData,
    };
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: var(--background-primary-gradient);
  padding: 15px 0 11px 0;

  .copyright-content {
    text-align: center;
    color: var(--c-white);
    font-size: var(--font-size--3xs);
    line-height: var(--font-size--xs);
  }
}

// big desktop
@media (min-width: $desktop-xxl-min) {
  .copyright {
    .copyright-content {
      max-width: var(--width-boxed);
      margin: 0 auto;
    }
  }
}

//small desktop
@media (min-width: $desktop-min) and (max-width: $desktop-xxl-min - 1) {
  .copyright {
    .copyright-content {
      width: 80%;
      margin: 0 auto;
    }
  }
}

//tablet
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .copyright {
    padding: 20px 0;

    .copyright-content {
      font-size: var(--font-size--xxs);
      margin: 0 var(--tablet-margin);
    }
  }
}

//mobile
@media (max-width: $mobile-max) {
  .copyright {
    padding: 20px 0;

    .copyright-content {
      font-size: var(--font-size--4xs);
      margin: 0 var(--mobile-margin);
    }
  }
}
</style>
