/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios from 'axios';

const useNexi = () => {
  const response: Ref<any> = ref(null);
  const macResponse: Ref<any> = ref(null);
  const successfulStateResponse: Ref<any> = ref(null);
  const success: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<any> = ref(null);
  const { $config } = useContext();

  const getNexiGeminiConfig = async () => {
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      const getNexiGeminiConfigResponse = await axios({
        method: 'get',
        url: '/api/nexi/getNexiGeminiConfig',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { tenant_id: $config.tenantId },
      });
      response.value = getNexiGeminiConfigResponse.data;
      success.value = true;
      if (getNexiGeminiConfigResponse?.data?.error) {
        error.value = getNexiGeminiConfigResponse.data.error;
        success.value = false;
      }
    } catch (e) {
      error.value = e;
    }
    loading.value = false;
  };

  const isSuccessfulResult = async (EventDetailData) => {
    success.value = false;
    error.value = null;
    loading.value = true;
    try {
      const SuccessfulState = await axios({
        method: 'get',
        url: '/api/nexi/isSuccessfulResult',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { tenant_id: $config.tenantId, event: EventDetailData },
      });
      successfulStateResponse.value = SuccessfulState.data.success;
      success.value = true;
      if (SuccessfulState?.data?.error) {
        error.value = SuccessfulState.data.error;
        success.value = false;
      }
    } catch (e) {
      error.value = e;
    }
    loading.value = false;
  };

  const getNexiGeminiMac = async (codTrans, divisa, importo) => {
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      const getNexiGeminiMacResponse = await axios({
        method: 'get',
        url: '/api/nexi/getNexiGeminiMac',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { tenant_id: $config.tenantId, codTrans, divisa, importo },
      });
      macResponse.value = getNexiGeminiMacResponse.data;
      success.value = true;
      if (getNexiGeminiMacResponse?.data?.error) {
        error.value = getNexiGeminiMacResponse.data.error;
        success.value = false;
      }
    } catch (e) {
      error.value = e;
    }
    loading.value = false;
  };

  return {
    getNexiGeminiConfig,
    getNexiGeminiMac,
    isSuccessfulResult,
    response,
    macResponse,
    successfulStateResponse,
    success,
    loading,
    error,
  };
};

export default useNexi;
