/* eslint-disable prefer-destructuring */
import { useRouter, computed } from '@nuxtjs/composition-api';

export const fixUrlForCurrentLocale = (path: string): string => {
  const router = useRouter();
  const currentLocale = computed(() => {
    let tempCurrentLocale = '';
    const currentRoute = router.currentRoute?.fullPath ? router.currentRoute?.fullPath.split('/') : null;
    if (currentRoute && Array.isArray(currentRoute) && currentRoute.length > 0) {
      tempCurrentLocale = currentRoute[1];
      if (tempCurrentLocale?.includes('?')) {
        const currentLocaleWithoutParameters = tempCurrentLocale.split('?');
        if (currentLocaleWithoutParameters && Array.isArray(currentLocaleWithoutParameters) && currentLocaleWithoutParameters.length > 0) {
          tempCurrentLocale = currentLocaleWithoutParameters[0];
        }
      }
    }
    return tempCurrentLocale;
  });

  const fixRouteForLocale = (routeToFix: string): string => {
    let newPath = routeToFix;
    if (routeToFix?.includes("'")) {
      newPath = routeToFix.replaceAll("'", '');
    }
    if (newPath?.[0] !== '/') {
      newPath = `/${newPath}`;
    }
    const routeArray = newPath.split('/');
    if (routeArray.length > 1) {
      const alreadyHasLocale = routeArray?.includes('it') || routeArray?.includes('en') || routeArray?.includes(currentLocale.value);
      if (alreadyHasLocale) {
        routeArray[1] = currentLocale.value;
      } else {
        routeArray[0] = currentLocale.value;
        routeArray.unshift('');
      }
      return routeArray.join('/');
    }
    return newPath === '/' ? `${newPath}${currentLocale.value}` : `${currentLocale.value}${newPath}`;
  };

  return fixRouteForLocale(path);
};
