/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios, { AxiosResponse } from 'axios';

interface NewsletterForm {
  FIRSTNAME: string;
  LASTNAME: string;
  PRIVACY: boolean;
  email: string;
}

const useBrevo = () => {
  const response: Ref<AxiosResponse> = ref(null);
  const success: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<any> = ref(null);

  const createContact = async (data: NewsletterForm) => {
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      const requestData = JSON.stringify({
        attributes: {
          FIRSTNAME: data.FIRSTNAME,
          LASTNAME: data.LASTNAME,
          PRIVACY: data.PRIVACY,
        },
        email: data.email,
        updateEnabled: false,
      });
      const createContactResponse = await axios({
        method: 'get',
        url: '/api/brevo/createContact',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { userData: requestData },
      });
      response.value = createContactResponse.data.data;
      success.value = true;
      if (createContactResponse?.data?.error) {
        error.value = createContactResponse.data.error;
        success.value = false;
      }
    } catch (e) {
      error.value = e;
    }
    loading.value = false;
  };

  const deleteContact = async (email: string) => {
    success.value = false;
    error.value = null;
    loading.value = true;
    try {
      const deleteContactResponse = await axios({
        method: 'post',
        url: '/api/brevo/deleteContact',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: {
          email,
        },
      });
      success.value = deleteContactResponse.data;
      if (deleteContactResponse?.data?.error) {
        success.value = false;
        error.value = deleteContactResponse.data.error;
      }
    } catch (e) {
      error.value = e;
      success.value = false;
    }
    loading.value = false;
  };

  const checkSubscription = async (email: string) => {
    error.value = null;
    loading.value = true;
    try {
      const checkContactResponse = await axios({
        method: 'get',
        url: '/api/brevo/checkContact',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: {
          email,
        },
      });
      loading.value = false;
      if (checkContactResponse?.data?.error) {
        if (!checkContactResponse.data.error.includes('404')) {
          error.value = checkContactResponse.data.error;
        }
        return false;
      }
      return true;
    } catch (e) {
      loading.value = false;
      if (!e?.message?.includes('404')) {
        error.value = e;
      }
      return false;
    }
  };

  return {
    createContact,
    deleteContact,
    checkSubscription,
    response,
    success,
    loading,
    error,
  };
};

export default useBrevo;
