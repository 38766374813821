/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, computed, useRoute } from '@nuxtjs/composition-api';
import { cartGetters, productGetters, useUser, userGetters, Product } from '@gemini-vsf/composables';
import { useCart } from '~/composables';
import dataManipulation from '~/helpers/dataManipulation';
import { jsonrepair } from 'jsonrepair';

interface GtmPagePush {
  event: string;
  content_name: string;
  content_url: string;
  item_id?: string | number;
}

interface ConsentCookie {
  stamp?: string;
  necessary?: boolean;
  preferences?: boolean;
  statistics?: boolean;
  marketing?: boolean;
  method?: string;
  ver?: number;
  utc?: number;
  region?: string;
}

const useGtm = () => {
  const {
    app: {
      $gtm: googleTagManager,
      i18n: { locales },
      $cookies,
    },
    app,
  } = useContext();
  // @ts-ignore
  const currency: string | null = $cookies.get('vsf-currency');
  const cookieConsent: ConsentCookie | null = $cookies.get('CookieConsent');
  const cookieConsentObject: ConsentCookie | null = cookieConsent ? JSON.parse(jsonrepair($cookies.get('CookieConsent'))) : cookieConsent;
  const canBeTraced = computed(() => !!cookieConsentObject?.statistics);
  const fullPath = useRoute();
  const currentLocaleCode = computed(() => fullPath.value.fullPath.split('/')[1]);
  // @ts-ignore
  const currentCurrency = computed(() => locales.find((l) => l.code === currentLocaleCode.value)?.defaultCurrency);
  const isClientAndHasCurrency = computed(() => !!process.client && currentCurrency.value);
  const { cart } = useCart();
  const { isAuthenticated, user } = useUser();
  const { arrayIsValidAndHasItems, vueArrayToNormalArray } = dataManipulation();

  const getCartProducts = () => {
    return (
      cartGetters
        .getItems(cart.value)
        .filter((p) => p.product && p.quantity && p.prices)
        .map((value, index) => ({
          item_id: productGetters.getProductMerchantSku(value.product).split('_')[0],
          item_name: productGetters.getName(value.product),
          // @ts-ignore
          item_brand: value?.product?.manufacturer_attribute?.label,
          price: value.prices.row_total.value ?? value.prices.row_regular_total.value,
          quantity: value.quantity,
          index,
          item_variant: productGetters.getProductMerchantSku(value.product),
        })) ?? []
    );
  };
  const checkoutGtmPush = (step: number, description: string, event: string) => {
    if (canBeTraced.value) {
      try {
        // @ts-ignore
        if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
          googleTagManager.push({
            event,
            ecommerce: {
              currencyCode: currency,
              checkout: {
                actionField: {
                  step,
                  description,
                  action: 'checkout',
                },
              },
            },
          });
        }
      } catch {
        console.warn('Could not send checkout GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const cartGtmPush = () => {
    if (canBeTraced.value) {
      try {
        const productsInCart = getCartProducts();
        // @ts-ignore
        if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
          googleTagManager.push({
            ecommerce: {
              items: { ...productsInCart },
              transaction_id: cart.value.id,
              value: cart.value.prices.grand_total.value,
              currency,
              affiliation: 'bestdigit.it',
              PageType: 'cart-page',
            },
            event: 'view_cart',
          });
        }
      } catch {
        console.warn('Could not send cart GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const categoryGtmPush = (products, categoryName) => {
    if (canBeTraced.value) {
      try {
        const productsList = products.filter(Boolean).map((p, index) => ({
          item_id: productGetters.getProductMerchantSku(p).split('_')[0] || p.merchant_sku,
          item_brand: p.manufacturer_attribute.label,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          item_name: productGetters.getName(p) || p.name,
          // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary, @typescript-eslint/no-unsafe-argument
          price:
            p.price_range?.minimum_price?.final_price?.value === p.price_range?.maximum_price?.final_price?.value
              ? p.price_range?.maximum_price?.final_price?.value
              : p.price_range?.minimum_price?.final_price?.value,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          item_category: categoryName,
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          position: index + 1,
        }));

        if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsList)) {
          googleTagManager.push({
            ecommerce: {
              items: {
                ...productsList,
              },
            },
            PageType: 'product-list',
            event: 'view_item_list',
          });
        }
      } catch {
        console.warn('Could not send category GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const customerGtmPush = () => {
    if (canBeTraced.value) {
      try {
        if (isClientAndHasCurrency.value) {
          if (isAuthenticated.value) {
            googleTagManager.push({
              customerLoggedIn: 1,
              // @ts-ignore
              firstname: userGetters.getFirstName(user.value),
              // @ts-ignore
              lastname: userGetters.getLastName(user.value),
              // @ts-ignore
              email: userGetters.getEmailAddress(user.value),
            });
            return;
          }
          googleTagManager.push({
            customerLoggedIn: 0,
            customerId: 0,
            customerGroupId: '1',
            customerGroupCode: 'GENERAL',
          });
        }
      } catch {
        console.warn('Could not send customer GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const pageGtmPush = (pageTitle: string, productMerchantSku = null) => {
    if (canBeTraced.value) {
      try {
        if (fullPath.value.fullPath && pageTitle) {
          const pagePushContent: GtmPagePush = {
            event: 'PageView',
            content_name: pageTitle,
            content_url: `https://www.bestdigit.it${pageTitle !== 'Cart' ? fullPath.value.fullPath : '/it/cart'}`,
          };
          if (productMerchantSku) pagePushContent.item_id = productMerchantSku;
          googleTagManager.push(pagePushContent);
        }
      } catch {
        console.warn('Could not send page GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const productGtmPush = (product) => {
    if (canBeTraced.value) {
      try {
        if (isClientAndHasCurrency.value && product) {
          const productDataGtm = {
            item_id: product.merchant_sku || product.sku,
            item_name: productGetters.getName(product),
            price: product.price_range.maximum_price.final_price.value,
            category: productGetters.getCategoryNameProductPage(product, ...product.categories) || [],
            // @ts-ignore
            list_sku_variants: vueArrayToNormalArray(productGetters.getListVariantSku(product)),
            item_brand: product?.manufacturer_attribute?.label,
          };
          googleTagManager.push({
            ecommerce: {
              currency,
              value: productDataGtm.price,
              items: [
                {
                  ...productDataGtm,
                  categoryId: product.categories.find((c) => c.name === productDataGtm.category).uid,
                },
              ],
              PageType: 'product-page',
            },
            event: 'view_item',
          });
        }
      } catch {
        console.warn('Could not send product GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const purchaseGtmPush = (order) => {
    if (canBeTraced.value) {
      try {
        if (isClientAndHasCurrency.value && order && order.transactionId) {
          googleTagManager.push({
            event: 'purchase',
            currency,
            ecommerce: {
              ...order,
            },
          });
        }
      } catch {
        console.warn('Could not send purchase GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const addToWishlistGtmPush = (product, variant?: Product) => {
    if (canBeTraced.value) {
      try {
        if (!!product && !!isClientAndHasCurrency.value) {
          const productWishlistGtmData = {
            item_id: variant?.merchant_sku || product.merchant_sku,
            item_name: product.name,
            price: variant?.price_range.maximum_price.final_price.value || productGetters.getFinalPrice(product),
            item_brand: product?.manufacturer_attribute?.label,
          };
          googleTagManager.push({
            ecommerce: {
              currency,
              value: productWishlistGtmData.price,
              items: [
                {
                  ...productWishlistGtmData,
                },
              ],
              quantity: 1,
            },
            event: 'add_to_wishlist',
          });
        }
      } catch {
        console.warn('Could not send wishlist GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const actionCartGtmPush = (product, quantity: number, event: string, gtmIndex: number, productPrice?: any) => {
    if (canBeTraced.value) {
      try {
        if (isClientAndHasCurrency.value && product && quantity) {
          googleTagManager.push({
            event: `${event}`,
            ecommerce: {
              items: [
                product?.vsf_typename === 'ConfigurableProduct'
                  ? {
                      item_id: product.configurable_product_options_selection?.variant.merchant_sku || productGetters.getProductMerchantSku(product),
                      item_name: productGetters.getName(product) || '',
                      price:
                        product.configurable_product_options_selection?.variant.price_range.maximum_price.final_price.value ||
                        productGetters.getFinalPrice(product),
                      item_brand: product?.manufacturer_attribute?.label,
                      index: gtmIndex || '',
                      quantity,
                    }
                  : {
                      item_id:
                        product.configurable_product_options_selection?.variant.merchant_sku ??
                        productGetters.getProductMerchantSku(product).split('_')[0],
                      item_name: productGetters.getName(product) || '',
                      item_brand: product?.manufacturer_attribute?.label,
                      price: productGetters.getFinalPrice(product),
                      index: gtmIndex ?? '',
                      item_variant:
                        product.configurable_product_options_selection?.variant.merchant_sku || productGetters.getProductMerchantSku(product),
                      quantity,
                    },
              ],
              value:
                event === 'remove_from_cart'
                  ? productPrice.row_total.value
                  : event === 'add_to_cart'
                  ? product.price_range.maximum_price.final_price.value
                  : productGetters.getFinalPrice(product),
              currency,
            },
          });
        }
      } catch {
        console.warn('Could not send cart GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  const searchGtmPush = (term: string) => {
    if (canBeTraced.value) {
      try {
        if (isClientAndHasCurrency.value && term) {
          googleTagManager.push({
            event: 'search',
            search_term: term,
          });
        }
      } catch {
        console.warn('Could not send search GTM event.');
      }
    } else {
      console.debug('Statistics cookie consent must be accepted to enable Google Analytics.');
    }
  };

  return {
    addToWishlistGtmPush,
    checkoutGtmPush,
    cartGtmPush,
    categoryGtmPush,
    customerGtmPush,
    pageGtmPush,
    productGtmPush,
    purchaseGtmPush,
    actionCartGtmPush,
    searchGtmPush,
  };
};

export default useGtm;
