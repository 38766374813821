<template>
  <div>
    <SfModal
      v-e2e="'newsletter-modal'"
      :visible="isNewsletterModalOpen"
      class="newsletter-modal"
      @close="closeModal"
    >
      <template #modal-bar>
        <SfBar
          class="sf-modal__bar smartphone-only"
          :close="true"
          :title="$t(barTitle)"
          @click:close="closeModal"
        />
      </template>
      <transition name="sf-fade" mode="out-in">
        <div class="form">
          <ValidationObserver v-slot="{ invalid }" key="sign-up">
            <ValidationProvider v-slot="{ errors }" rules="required|email">
              <CustomInput
                v-model="form.email"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
                name="email"
                id="email"
                :placeholder="$t('Your email')"
                class="form__element"
              />
              <p
                class="newsletter-form-custom-error"
                v-if="!errors[0] && !form.email && isValidEmail"
              >
                {{ $t('Invalid email') }}
              </p>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <CustomInput
                v-model="form.FIRSTNAME"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
                name="first-name"
                :placeholder="$t('First Name')"
                class="form__element"
              />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <CustomInput
                v-model="form.LASTNAME"
                v-e2e="'login-modal-lastName'"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
                name="last-name"
                :placeholder="$t('Last Name')"
                class="form__element"
              />
            </ValidationProvider>
            <ConditionsAcceptance
              v-e2e="'privacy'"
              class="form__element"
              @valueChange="changePrivacy"
            />
            <recaptcha v-if="isRecaptchaEnabled" />
            <div class="newsletter-form-custom-error" v-if="error.register">
              {{ $t(error.register) }}
            </div>
            <SfLoader v-if="brevoLoading" :loading="brevoLoading" />
            <CustomButton
              v-else
              :disabled="invalid || !privacyChecked"
              @click="handleForm"
            >
              {{ $t('Register') }}
            </CustomButton>
          </ValidationObserver>
        </div>
      </transition>
    </SfModal>
  </div>
</template>

<script>
import {
  ref,
  watch,
  reactive,
  defineComponent,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import { SfModal, SfBar, SfLoader } from '@storefront-ui/vue';
import CustomInput from '~/components/CustomInput.vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed, regex } from 'vee-validate/dist/rules';
import { useWishlist } from '@gemini-vsf/composables';
import { useUiState, useBrevo, useUiNotification } from '~/composables';
import CustomButton from '@/components/CustomButton.vue';
import ConditionsAcceptance from '@/components/ConditionsAcceptance.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { validEmailRegexp } from '~/helpers/customer/regex';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    CustomInput,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    CustomButton,
    ConditionsAcceptance,
    SfLoader,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isNewsletterModalOpen, toggleNewsletterModal } = useUiState();
    const { send: sendNotification } = useUiNotification();
    const { $recaptcha, $config } = useContext();
    const translate = useI18n();
    const isSubscribed = ref(false);
    const privacyChecked = ref(false);
    const form = ref({ email: props.email });
    const isValidEmail = computed(() =>
      validEmailRegexp.test(form.value.email)
    );
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );

    const {
      createContact,
      success: brevoSuccess,
      loading: brevoLoading,
      error: brevoError,
    } = useBrevo();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { load: loadWishlist } = useWishlist('GlobalWishlist');
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const isLoggedIn = ref(false);

    const barTitle = computed(() => {
      return 'Register';
    });

    const error = reactive({
      register: null,
    });

    const resetErrorValues = () => {
      error.register = null;
    };

    watch(isNewsletterModalOpen, () => {
      if (isNewsletterModalOpen) {
        form.value = { email: props.email };
        resetErrorValues();
      }

      if (isSubscribed.value === false) {
        isSubscribed.value = true;
      }
    });

    const closeModal = () => {
      toggleNewsletterModal();
    };

    const handleForm = async () => {
      resetErrorValues();

      // ToDo: export recaptcha validator to use it here
      // if (isRecaptchaEnabled.value) {
      //   $recaptcha.init();
      // }

      await createContact({
        FIRSTNAME: form.value.FIRSTNAME,
        LASTNAME: form.value.LASTNAME,
        PRIVACY: privacyChecked.value,
        email: form.value.email,
      });

      if (brevoSuccess.value) {
        sendNotification({
          id: Symbol('need_help_form_success'),
          message: translate.t(
            'You subscribed successfully to our newsletter!'
          ),
          type: 'success',
          icon: 'success',
          persist: false,
          title: translate.t('Thank you!'),
        });
        toggleNewsletterModal();
      }
      if (brevoError.value) {
        sendNotification({
          id: Symbol('user_form_error'),
          message: translate.t(
            'Newsletter subscription failed: data is not valid or email is already subscribed!'
          ),
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Error',
        });
      }

      // if (isRecaptchaEnabled.value) {
      //   // reset recaptcha
      //   $recaptcha.reset();
      // }
    };

    const changePrivacy = (value) => {
      privacyChecked.value = value;
      form.value.PRIVACY = value;
    };

    return {
      barTitle,
      closeModal,
      error,
      form,
      isNewsletterModalOpen,
      isSubscribed,
      isLoggedIn,
      isRecaptchaEnabled,
      privacyChecked,
      changePrivacy,
      handleForm,
      isValidEmail,
      brevoLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.newsletter-modal {
  --modal-index: 50;
  --overlay-z-index: 49;
}

.form {
  &__element {
    margin: 0 0 var(--spacer-sm) 0;
  }

  .custom-button {
    width: 100%;
    margin-bottom: var(--spacer-xl);
  }
  .newsletter-form-custom-error {
    color: red;
    text-align: center;
    font-family: var(--font-family--secondary);
  }
}

.forgot-password-message {
  font-size: var(--font-size--4xs);
  font-family: var(--font-family--secondary);
  color: var(--c-grey-2);
  margin: 0 0 var(--spacer-lg);
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 var(--spacer-xl) 0;
}

.bottom {
  text-align: center;
  font-size: var(--font-size--3xs);
  font-family: var(--font-family--secondary);
  color: var(--c-grey-2);
  line-height: var(--font-size--s);
}

.thank-you {
  &__paragraph {
    font-size: var(--font-size--4xs);
    color: var(--c-grey-2);
    &--bold {
      font-weight: var(--font-weight--semibold);
    }
  }
}
.password-error {
  //margin-bottom: 55px;
}
</style>

<style lang="scss">
.newsletter-modal {
  .sf-overlay {
    background-color: var(--c-grey-3);
  }

  *::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: var(--c-grey-1);
  }

  *::-webkit-scrollbar {
    border-radius: 15px;
    width: 10px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: var(--c-grey-9);
    width: 10px;
  }

  .custom-button.disabled {
    opacity: 30%;
  }

  .sf-modal__container {
    z-index: 99;
    border-radius: 10px;
    width: 450px;
    height: 500px;

    .sf-icon {
      width: var(--spacer-sm);
      height: var(--spacer-sm);
    }

    .sf-button--pure {
      --button-color: var(--c-grey-8);
    }

    .sf-button--text {
      font-size: var(--font-size--4xs);
    }

    .sf-modal__content {
      padding: 106px 35px 35px;
    }

    .sf-custom-input {
      .form__element {
        height: 53px;
      }
    }

    .form__element[data-testid='password'] {
      margin-bottom: 10px;
    }

    .sf-input__error-message {
      font-size: var(--font-size--5xs);
      margin: 10px 10px 10px 0;
    }

    .sf-checkbox__message {
      font-size: var(--font-size--5xs);
    }

    input,
    select {
      box-shadow: none;
      outline: none;

      &:active,
      &:focus,
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }

    .form {
      label.sf-input__label {
        color: var(--c-grey-3);
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--xs);
        font-family: var(--font-family--secondary);
      }

      .sf-checkbox__label {
        font-size: var(--font-size--4xs);
        font-family: var(--font-family--secondary);
        color: var(--c-grey-2);
      }
    }

    .action *,
    .action {
      font-size: var(--font-size--4xs);
      font-family: var(--font-family--secondary);
      color: var(--c-grey-2);
      gap: 5px;
    }

    .sf-bar {
      background-color: #222a24;

      .sf-icon {
        fill: var(--c-white);
      }
    }

    .sf-bar__title {
      color: var(--c-white);
      font-size: var(--font-size--3xs);
      font-weight: 400;
    }
  }
}

@media (max-width: $tablet-max) {
  .newsletter-modal {
    .sf-modal__container {
      width: 90vw;
      margin: 0 auto;

      .sf-modal__content {
        padding: 10vw 5vw 5vw;
      }

      .sf-bar {
        background-color: var(--c-grey-6);
      }

      .sf-input__error-message {
        margin: 0;
      }
    }
  }
}
</style>
