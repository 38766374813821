import { useI18n } from '~/helpers/hooks/usei18n';

export const useCurrentLocale = () => {
  const { locale } = useI18n();
  const fixUrlForCurrentLocale = (relativeLink: string): string => {
    if (relativeLink?.startsWith('http')) {
      return relativeLink;
    }
    const fixedLink = relativeLink?.startsWith('/') ? relativeLink : `/${relativeLink}`;
    return `/${locale}${fixedLink}`;
  };
  return { fixUrlForCurrentLocale };
};
