<template>
  <SfFooter :column="1" multiple class="footer">
    <div class="footer-row-1">
      <SfFooterColumn
        :title="footerAddress.sections.title"
        class="footer-column-titled contacts"
      >
        <Contacts
          :address="footerAddress.sections.address"
          :otherinfo="footerAddress.sections.links"
        />
      </SfFooterColumn>

      <SfFooterColumn
        v-for="(section, indexFooterLink) in footerLink.sections"
        :key="'footerLink-' + indexFooterLink"
        :title="section.label"
        class="footer-column-titled border"
        :classes="[section.name]"
      >
        <FooterListCustom
          :items="section.links"
          :border="true"
          :is-account="section.name === 'my_account'"
        />
      </SfFooterColumn>

      <SfFooterColumn
        class="footer-column-titled newsletter-subscribe"
        :title="footerNewsletter.title"
      >
        <NewsletterSubscribe :subtitle="footerNewsletter.description" />
      </SfFooterColumn>
    </div>
    <div class="footer-row-2">
      <SfFooterColumn class="footer-column-no-title payments">
        <ImageList :items="footerPayments.sections.links" />
      </SfFooterColumn>
      <SfFooterColumn class="footer-column-no-title social">
        <ImageList
          :title="footerSocial.sections.title"
          :items="footerSocial.sections.links"
          :divider="true"
          :additional-image="footerSocial.sections.additionalImage"
        />
      </SfFooterColumn>
    </div>
  </SfFooter>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { SfFooter } from '@storefront-ui/vue';
import FooterListCustom from '@/components/Footer/FooterListCustom.vue';
import NewsletterSubscribe from '~/components/Footer/NewsletterSubscribe.vue';
import ImageList from '@/components/Footer/ImageList.vue';
import Contacts from '~/components/Footer/Contacts.vue';
import { useI18n } from '@/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';

export default defineComponent({
  components: {
    FooterListCustom,
    SfFooter,
    NewsletterSubscribe,
    ImageList,
    Contacts,
  },
  setup() {
    const translate = useI18n();
    const { locale } = translate;

    const identifierFooterLinks = `footer-links-${locale}`;
    const identifierFooterAddress = `footer-address-${locale}`;
    const identifierFooterNewsletter = `footer-newsletter-${locale}`;
    const identifierFooterPayments = `footer-payments-${locale}`;
    const identifierFooterSocial = `footer-social-${locale}`;

    const { blocks, loadBlocks } = useContent(`cmsBlockFooter-${locale}`);

    const footerLink = ref({
      sections: [],
    });
    const footerAddress = ref({
      sections: [],
    });
    const footerNewsletter = ref({});
    const footerPayments = ref({
      sections: [],
    });
    const footerSocial = ref({
      sections: [],
    });

    useFetch(async () => {
      await loadBlocks({
        identifiers: [
          identifierFooterLinks,
          identifierFooterAddress,
          identifierFooterNewsletter,
          identifierFooterPayments,
          identifierFooterSocial,
        ],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      // check if blocks contains the identifier
      footerLink.value = blocksContent[identifierFooterLinks] ?? {
        sections: [],
      };
      footerAddress.value = blocksContent[identifierFooterAddress] ?? {
        sections: [],
      };
      footerNewsletter.value = blocksContent[identifierFooterNewsletter] ?? {};
      footerPayments.value = blocksContent[identifierFooterPayments] ?? {
        sections: [],
      };
      footerSocial.value = blocksContent[identifierFooterSocial] ?? {
        sections: [],
      };
    });

    return {
      blocks,
      locale,
      footerLink,
      footerAddress,
      footerNewsletter,
      footerPayments,
      footerSocial,
    };
  },
  data() {
    return {
      additionalImage: { image: 'assets/feedaty.png', alt: 'feedaty' },
    };
  },
});
</script>

<style lang="scss">
.footer {
  background: var(--c-grey-6);
  @include for-desktop {
    margin-bottom: 0;
  }
}
.sf-footer {
  @include for-desktop {
    padding-bottom: 0;
    margin-top: 0;
  }
  &__container {
    margin: var(--spacer-sm);
    @include for-desktop {
      //max-width: 69rem;
      margin: 0 auto;
    }

    div.footer-row-1 > :first-child {
      padding: 0 20px 0 0;
    }

    div.footer-row-1 > :last-child {
      padding: 0 0 0 20px;
    }

    .sf-footer-column {
      background: var(--c-grey-6);
      &.border {
        border-left: solid 1px var(--c-grey-2);
      }
      &.footer-column-no-title {
        background: var(--c-grey-6);
        padding: 0;
        .sf-footer-column__title {
          display: none;
        }
      }

      &.footer-column-titled {
        padding: 0 20px;

        .sf-footer-column__title {
          background: var(--c-grey-6);
          &.sf-button {
            text-align: left;
            pointer-events: none;
            padding: 0;
          }

          .sf-footer-column__chevron {
            display: none;
          }
          .sf-heading__title {
            text-align: left;
            font-size: var(--font-size--xs);
            line-height: var(--font-size--xs);
            font-weight: var(--font-weight-semibold);
            text-transform: none;
            color: var(--c-grey-1);
          }
        }
      }
    }
  }
}

//mobile
@media (max-width: $mobile-max) {
  .footer {
    .sf-footer__container {
      margin: 0 var(--mobile-margin);
      padding: 20px 0 50px 0;
      .footer-row-1 {
        display: grid;
        grid-template-columns: 1fr;
        & > :first-child,
        & > :last-child {
          padding: 30px 0 0;
        }
      }
      .footer-row-2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .payments {
          order: 2;
          padding: 30px 0 0;
          .footer-image-container {
            padding: 0;
          }
        }
        .social {
          order: 1;
          .footer-image-container.divider {
            padding: 0;
          }
        }
      }
      .sf-footer-column {
        padding: 30px 0 0;
        margin-top: 5px;
        &.border {
          border-left: none;
        }
        &.footer-column-titled {
          .sf-footer-column__title {
            text-transform: none;
            font-size: var(--font-size--xs);
            font-weight: var(--font-weight-semibold);
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}

//tablet
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .footer {
    .sf-footer__container {
      margin: 0 var(--tablet-margin);
      padding: 20px 0 50px 0;
      .footer-row-1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > :first-child,
        & > :last-child {
          padding: 30px 0 0;
        }
        .newsletter-subscribe {
          grid-column: 1/3;
        }
      }
      .footer-row-2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .payments {
          order: 2;
          padding: 30px 0 0;
          .footer-image-container {
            padding: 0;
          }
        }
        .social {
          order: 1;
          .footer-image-container.divider {
            padding: 0;
          }
        }
      }
      .sf-footer-column {
        padding: 30px 0 0;
        margin-top: 5px;
        &.border {
          border-left: none;
        }
        &.footer-column-titled {
          .sf-footer-column__title {
            text-transform: none;
            font-size: var(--font-size--xs);
            font-weight: var(--font-weight-semibold);
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}
//desktop
@media (min-width: $desktop-min) and (max-width: $desktop-xxl-min - 1) {
  .footer {
    .sf-footer__container {
      width: 100%;
      margin: 0 auto;
      .footer-row-1 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        margin-bottom: 70px;
        .newsletter-subscribe {
          grid-column: 5 / 7;
        }
      }
      .footer-row-2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        align-items: flex-start;
        margin-bottom: 50px;
        .payments {
          grid-column: 1 / 5;
        }
        .social {
          grid-column: 5 / 7;
        }
      }
    }
  }
}

@media (min-width: $desktop-xxl-min) {
  .footer {
    .sf-footer__container {
      max-width: var(--width-boxed);
      margin: 0 auto;
      .footer-row-1 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        margin-bottom: 70px;
        .newsletter-subscribe {
          grid-column: 5 / 7;
        }
      }
      .footer-row-2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        align-items: flex-start;
        margin-bottom: 50px;
        .payments {
          grid-column: 1 / 5;
        }
        .social {
          grid-column: 5 / 7;
        }
      }
    }
  }
}
</style>
