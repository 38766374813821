





























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { Menu } from '@gemini-vsf/api-client';
import { useUiHelpers } from '~/composables';
// import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import { preventBodyScroll } from '~/helpers/preventBodyScroll';
import HeaderNavigationItem from './HeaderNavigationItem.vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationSubcategories: () =>
      import(
        '~/components/Header/Navigation/HeaderNavigationSubcategories.vue'
      ),
    HeaderNavigationItem,
  },
  props: {
    menu: {
      type: Object as PropType<Menu>,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();

    const currentMenuItem = ref(null);
    const lvl0ItemRefs = ref();
    const hasFocus = ref(false);
    let lvl0ItemFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;

    const setCurrentMenuItem = (menuItem) => {
      currentMenuItem.value = menuItem;
      if (menuItem === null) {
        preventBodyScroll('removeClass', 'open_menu');
      }
    };

    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0ItemFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentMenuItem(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const onMouseEnter = (menuItem) => {
      preventBodyScroll('addClass', 'open_menu');
      currentMenuItem.value = menuItem;
      focusedElement = null;
      hasFocus.value = false;
    };

    return {
      getCatLink,
      setCurrentMenuItem,
      currentMenuItem,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0ItemRefs,
      hasFocus,
      onMouseEnter,
    };
  },
});
