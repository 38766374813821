<template>
  <div class="sf-custom-input">
    <SfInput
      :id="nameWithoutWhitespace"
      class="sf-custom-input__input"
      :class="{ 'sf-input--is-password': isPassword }"
      :name="name"
      :type="type"
      :value="value"
      :valid="valid"
      :required="required"
      :disabled="disabled"
      :has-show-password="hasShowPassword"
      :label="label"
      :button-text="buttonText"
      :icon="icon"
      :icon-color="iconColor"
      :hover-icon-color="hoverIconColor"
      v-bind="$attrs"
      :placeholder="$t(placeholder)"
      v-on="listeners"
      @input="handleChange"
    >
      <template #error-message>
        <div />
      </template>
    </SfInput>
    <transition name="sf-fade">
      <div v-if="errorMessage" class="sf-input__error-message">
        <div
          :id="`${nameWithoutWhitespace}-error`"
          :class="{ 'display-none': valid }"
          aria-live="assertive"
        >
          {{ errorMessage }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { SfInput } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomInput',
  components: {
    SfInput,
  },
  model: {
    prop: 'value',
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String, Object, null],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: [Object, String],
      default: () => ({}),
    },
    buttonText: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    hoverIconColor: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.value);

    const handleChange = (inputChange) => {
      inputValue.value = inputChange;
      emit('input', inputChange);
    };

    return {
      inputValue,
      handleChange,
    };
  },
  computed: {
    nameWithoutWhitespace() {
      return this.name.replace(/\s/g, '');
    },
    listeners() {
      return {
        // eslint-disable-next-line vue/no-deprecated-dollar-listeners-api
        ...this.$listeners,
        input: (event) => this.$emit('input', event),
      };
    },
    isPassword() {
      return this.type === 'password' && this.hasShowPassword;
    },
  },
});
</script>

<style lang="scss">
.sf-custom-input {
  .sf-input__error-message > div {
    text-align: center;
    font-size: var(--font-size--5xs);
    margin-top: 2px;
  }
}
</style>
