<template>
  <div id="wishlist">
    <SfSidebar
      v-e2e="'sidebar-wishlist'"
      :visible="isWishlistSidebarOpen"
      :button="false"
      class="sidebar sf-sidebar--right"
      @close="toggleWishlistSidebar"
      position="right"
    >
      <template #circle-icon>
        <div>
          <SfProperty
            v-if="totalItems"
            class="sf-property--large wishlist-summary"
            :name="$t('My wishlist')"
            :value="totalItems"
          />
          <div class="close-icon" @click="toggleWishlistSidebar">
            <svg
              width="14"
              height="14"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L17 17" stroke="#171717" stroke-width="2" />
              <path d="M17 1L1 17" stroke="#171717" stroke-width="2" />
            </svg>
          </div>
        </div>
      </template>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <template #content-top>
        <SfProperty
          v-if="totalItems"
          class="sf-property--large cart-summary"
          :name="$t('Total items')"
          :value="totalItems"
        />
      </template>
      <transition name="fade" mode="out-in">
        <div
          v-if="wishlistItems.length > 0"
          key="my-wishlist"
          class="my-wishlist"
        >
          <div class="collected-product-list">
            <SfCollectedProduct
              v-for="(wishlistItem, i) in wishlistItems"
              :key="i"
              :qty="wishlistGetters.getItemQty(wishlistItem)"
              :image="
                !!wishlistGetters.getItemImage(wishlistItem)
                  ? wishlistGetters.getItemImage(wishlistItem)
                  : '/assets/product_placeholder.svg'
              "
              :title="wishlistGetters.getItemName(wishlistItem)"
              :link="
                fixUrlForCurrentLocale(buildProductUrl(wishlistItem.product))
              "
              :regular-price="
                $n(
                  wishlistGetters.getItemPrice(wishlistItem).regular,
                  'currency'
                )
              "
              :special-price="
                wishlistGetters.getItemPrice(wishlistItem).special > 0
                  ? $n(
                      wishlistGetters.getItemPrice(wishlistItem).special,
                      'currency'
                    )
                  : null
              "
              :stock="99999"
              class="collected-product"
            >
              <template #image>
                <SfLink
                  :link="
                    fixUrlForCurrentLocale(
                      buildProductUrl(wishlistItem.product)
                    )
                  "
                >
                  <nuxt-img
                    :src="
                      !!wishlistGetters.getItemImage(wishlistItem)
                        ? wishlistGetters.getItemImage(wishlistItem)
                        : '/assets/product_placeholder.svg'
                    "
                    :alt="
                      wishlistGetters.getItemName(wishlistItem) ||
                      `wishlist-sidebar-product-card-${i}`
                    "
                    :width="140"
                    :height="200"
                    class="sf-collected-product__image"
                    preload
                    loading="lazy"
                  />
                </SfLink>
              </template>
              <template #configuration>
                <div />
              </template>
              <template #actions>
                <div class="action-wrapper">
                  <div>
                    <CustomButton
                      v-if="canAddToCart(wishlistItem)"
                      class="sf-button--text desktop-only"
                      icon=""
                      :primary="false"
                      @click="handleAddFromWishlist(wishlistItem)"
                    >
                      {{ $t('Add to cart') }}
                    </CustomButton>

                    <div
                      v-if="canAddToCart(wishlistItem)"
                      class="add-to-cart-mobile-action smartphone-only"
                      @click="handleAddFromWishlist(wishlistItem)"
                    >
                      <CarrelloIcon></CarrelloIcon>
                    </div>
                    <div
                      class="remove-from-wishlist-mobile-action"
                      @click="
                        sendToRemove({
                          product: wishlistItem.product,
                        })
                      "
                    >
                      <TrashIcon></TrashIcon>
                    </div>
                  </div>
                  <span
                    class="empty-action"
                    v-if="!canAddToCart(wishlistItem)"
                    >{{ $t('this product cannot be added to your cart') }}</span
                  >
                </div>
              </template>
              <template #more-actions> <div /></template>
              <template #input>
                <div />
              </template>
              <template #remove>
                <div class="smartphone-only" />
              </template>
            </SfCollectedProduct>
          </div>
        </div>
        <div v-else key="empty-wishlist" class="empty-wishlist">
          <div class="empty-wishlist__banner">
            <SfHeading
              :title="$t('Your wishlist is empty')"
              :description="
                $t('Looks like you haven’t added any items to the Wishlist.')
              "
              :level="4"
              class="empty-wishlist__label"
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <div class="wishlist__cta">
          <CustomButton
            class="sf-button--full-width color-secondary"
            :primary="false"
            @click="toggleWishlistSidebar"
          >
            {{ $t('Continue shopping') }}
          </CustomButton>
        </div>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  SfSidebar,
  SfHeading,
  SfProperty,
  SfCollectedProduct,
  SfLink,
} from '@storefront-ui/vue';
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { CarrelloIcon } from '~/components/General/Icons';
import {
  useUser,
  wishlistGetters,
  productGetters,
} from '@gemini-vsf/composables';
import {
  useUiState,
  useImage,
  useUrlHelpers,
  useWishlist,
  useWindow,
  useGtm,
  useCart,
  useUiNotification,
} from '~/composables';
import { fixUrlForCurrentLocale } from '~/helpers/routeFixer';
import CustomButton from '~/components/CustomButton.vue';
import TrashIcon from '~/components/Checkout/TrashIcon.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAdditionalServices } from '~/helpers/cart/additionalServices';

export default defineComponent({
  name: 'WishlistSidebar',
  methods: { fixUrlForCurrentLocale },
  components: {
    CustomButton,
    TrashIcon,
    CarrelloIcon,
    SfSidebar,
    SfHeading,
    SfProperty,
    SfCollectedProduct,
    SfLink,
  },
  setup() {
    const { addItemToCart, wishlist, removeItemFromWishlist, updateItem } =
      useWishlist();
    const { bulkUpdateItemsQty, cart, error: cartError } = useCart();
    const { buildProductUrl } = useUrlHelpers();
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    const { actionCartGtmPush } = useGtm();
    const { handleHtmlClass } = useWindow();
    const { updateServices } = useAdditionalServices();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const wishlistItems = computed(() =>
      wishlistGetters.getItems(wishlist.value)
    );
    const { send: sendNotification } = useUiNotification();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const totals = computed(() => wishlistGetters.getTotals(wishlist.value));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const totalItems = computed(() => wishlistItems?.value?.length);
    const isLoaderVisible = ref(false);
    const gtmIndex = ref(0);
    const trans = useI18n();
    const getAttributes = (product) =>
      product?.product?.configurable_options || [];

    const { getGeminiImage, imageSizes } = useImage();

    const changeItemQuantity = async (wishlistItem, quantity) => {
      if (quantity > 0) {
        updateItem({
          product: wishlistItem.product,
          quantity,
        });
      } else {
        await removeItemFromWishlist(wishlistItem);
        sendNotification({
          id: Symbol(''),
          message: `${wishlistItem.product.name} ${trans.t(
            'removed from wishlist'
          )}`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Success',
        });
      }
    };

    const sendToRemove = async (wishlistItem) => {
      await removeItemFromWishlist(wishlistItem);
      sendNotification({
        id: Symbol(''),
        message: `${wishlistItem.product.name} ${trans.t(
          'removed from wishlist'
        )}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Success',
      });
    };

    watch(isWishlistSidebarOpen, (newValue) => {
      if (newValue) {
        handleHtmlClass('addClass', 'no-scroll');
      } else {
        handleHtmlClass('removeClass', 'no-scroll');
      }
    });

    const canAddToCart = (wishlistItem) => {
      const inStock = wishlistItem.product?.stock_status || '';
      const stockLeft =
        wishlistItem.product?.only_x_left_in_stock === null
          ? true
          : wishlistItem.qty <= wishlistItem.product?.only_x_left_in_stock;
      return inStock && stockLeft;
    };

    const handleAddFromWishlist = async (wishlistItem) => {
      await addItemToCart(wishlistItem);
      if (!cartError.value?.addItem) {
        const notesObject =
          cart.value?.notes &&
          cart.value?.notes !== '' &&
          cart.value?.notes !== '{}'
            ? JSON.parse(cart.value.notes)
            : {};
        await updateServices(
          cart.value,
          wishlistItem.product.sku,
          notesObject,
          bulkUpdateItemsQty
        );
        actionCartGtmPush(
          wishlistItem.product,
          1,
          'add_to_cart',
          gtmIndex.value
        );
        gtmIndex.value += 1;
      }
    };

    return {
      getAttributes,
      isAuthenticated,
      isWishlistSidebarOpen,
      wishlistItems,
      removeItemFromWishlist,
      toggleWishlistSidebar,
      totalItems,
      totals,
      wishlistGetters,
      wishlist,
      productGetters,
      getGeminiImage,
      imageSizes,
      buildProductUrl,
      changeItemQuantity,
      canAddToCart,
      addItemToCart,
      isLoaderVisible,
      sendToRemove,
      handleAddFromWishlist,
    };
  },
});
</script>

<style lang="scss" scoped>
#wishlist {
  .wishlist-summary {
    border-bottom: 0;
    display: flex;
    margin: 60px 65px 24px 65px;
    padding-bottom: 20px;
    @media (max-width: $tablet-max) {
      padding: 0 var(--spacer-sm);
      margin: 0 1.25rem;
    }
  }

  .wishlist__cta {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4.9375rem;
  }
  .action-wrapper {
    display: flex;
    .custom-button {
      width: 100%;
      display: inline-block;
    }
    .add-to-cart-mobile-action {
      &:hover {
        cursor: pointer;
      }
    }
    .remove-from-wishlist-mobile-action {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.sidebar {
  --sidebar-z-index: 100;
  --overlay-z-index: 100;
  --sidebar-top-padding: var(--spacer-lg) var(--spacer-base) 0
    var(--spacer-base);
  --sidebar-content-padding: var(--spacer-lg) var(--spacer-base);
}

.title-wrapper {
  margin: 70px 0 0 32px;
}

.close-icon {
  position: fixed;
  right: 65px;
  top: 80px;
  cursor: pointer;
}

.my-wishlist {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__total-items {
    font: var(--font-weight--normal) var(--font-size--lg) / 1.6
      var(--font-family--secondary);
    color: var(--c-link);
    margin: 0;
  }
  &__total-price {
    --property-name-font-size: var(--font-size--xl);
    --price-font-size: var(--font-size--xl);
    margin: 0 0 var(--spacer-xl) 0;
    &-label {
      font: var(--font-weight--normal) var(--font-size--2xl) / 1.6
        var(--font-family--secondary);
      color: var(--c-link);
    }
  }
  .empty-action {
    color: red;
    font-weight: bold;
    font-size: 12px;
  }
}
.empty-wishlist {
  display: flex;
  flex: 1;
  flex-direction: column;
  &__banner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__label,
  &__description {
    text-align: center;
  }
  &__label {
    --heading-description-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-margin: 0 0 var(--spacer-xl) 0;
    --heading-title-color: var(--c-primary);
    --heading-title-font-weight: var(--font-weight--semibold);
    @include for-desktop {
      --heading-title-font-size: var(--font-size--xl);
      --heading-title-margin: 0 0 var(--spacer-sm) 0;
    }
  }
  &__icon {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }
}
.heading {
  &__wrapper {
    --heading-title-color: var(--c-link);
    --heading-title-font-weight: var(--font-weight--semibold);
    display: flex;
    justify-content: space-between;
  }
}

.sidebar-bottom {
  margin: auto 0 0 0;
}

.collected-product {
  flex: 1;
  padding: 0;
}
@media (max-width: $tablet-max) {
  .close-icon {
    top: 25px;
    right: 25px;
  }
}
</style>

<style lang="scss">
#wishlist {
  .sf-bar__icon {
    display: none;
  }

  .sf-bar {
    background: var(--c-white);
    border-bottom: 1px solid #e9ecf1;
    height: 54px;
    display: none;
  }
  .wishlist__cta {
    @media (max-width: $tablet-max) {
      width: 100%;
      button {
        width: 95%;
      }
    }
  }
  .sf-price__regular {
    font-size: var(--font-size--xs);
    font-family: var(--font-family--secondary-bold);
    color: var(--c-primary);
  }
  .sf-collected-product__image {
    box-shadow: 0 10px 35px var(--c-transparent-white);
    border-radius: 5px;
    max-height: 170px;
    padding: 15px;
    @media (max-width: $tablet-max) {
      padding: 0px;
      max-height: none;
      height: 100%;
      width: 100%;
    }
  }
  .sf-collected-product__title {
    margin: 0;
    @media (max-width: $tablet-max) {
      font-size: var(--font-size--3xs);
      line-height: var(--font-size--s);
    }
  }
  .sf-collected-product__aside {
    @media (max-width: $tablet-max) {
      flex: 40%;
      max-width: 30%;
    }
  }
  .sf-collected-product__main {
    margin: 0;
    padding-left: 26px;
    padding-bottom: 10px;
  }
  .sf-collected-product:hover::after {
    display: none;
  }
  .sf-collected-product {
    --collected-product-actions-display: flex;
    --collected-product-z-index: 1;
    --collected-product-remove-opacity: 1;
    --collected-product-configuration-display: none;
  }
  .sf-collected-product__title-wraper {
    a {
      font-size: var(--font-size--2xs);
      font-weight: bold;
      letter-spacing: 0;
      line-height: var(--font-size--s);
      font-family: var(--font-family--secondary-bold);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media (max-width: $tablet-max) {
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--s);
      }
    }
  }
  .wishlist-summary {
    .sf-property {
      &__name {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size--sm);
        font-family: var(--font-family--secondary);
        line-height: 55px;
        &::after {
          display: none;
        }
      }
      &__value {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size--sm);
        font-family: var(--font-family--secondary);
        line-height: 55px;
        &::after {
          content: ')';
        }
        &::before {
          content: '(';
        }
      }
    }
  }
  .sf-sidebar__content {
    @media (min-width: $desktop-min) {
      padding: 0 65px;
    }
  }
  .action-wrapper {
    div:first-of-type {
      display: flex;
      align-items: center;
      @media (max-width: $desktop-min) {
        max-width: 150px;
      }
      width: 100%;
      gap: 15px;
    }
    button {
      padding: 15px 20px;
      @media (max-width: $tablet-max) {
        width: 85%;
      }
    }
  }
}

.my-wishlist {
  .sf-collected-product {
    &__image {
      object-fit: contain !important;
      background-color: transparent;
    }
  }
}

@media (min-width: $desktop-min) {
  #wishlist {
    & .sf-sidebar__aside {
      width: 570px;
    }
  }
}
#wishlist {
  .sf-collected-product__main {
    justify-content: center;
    @media (max-width: $tablet-max) {
      justify-content: space-between;
    }
  }
  .sf-heading__title.h3 {
    font-size: 32px;
  }
  .empty-wishlist {
    &__label {
      .sf-heading {
        &__description {
          font-size: 16px;
        }
        &__title {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
