<template>
  <SfList class="contacts">
    <SfListItem v-for="(item, index) in address" :key="`contact-item-${index}`">
      <div class="sf-menu-item__label">{{ item.value }}</div>
    </SfListItem>
    <br />
    <SfListItem v-for="(item, index) in otherinfo" :key="index">
      <SfMenuItem v-if="item.url" :label="item.label" :link="item.url" />
      <div v-else class="sf-menu-item__label">{{ item.label }}</div>
    </SfListItem>
  </SfList>
</template>

<script>
import { SfList, SfMenuItem } from '@storefront-ui/vue';

export default {
  name: 'Contacts',
  components: {
    SfList,
    SfMenuItem,
  },
  props: {
    address: {
      type: Array,
      default: null,
    },
    otherinfo: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.sf-list.contacts {
  .sf-list__item {
    margin: 0;
    .sf-menu-item__label {
      font-size: var(--font-size--3xs);
      line-height: var(--font-size--xs);
      color: var(--c-grey-3);
    }
    .sf-link.sf-menu-item {
      .sf-menu-item__label {
        &:hover,
        &:focus {
          text-decoration: underline;
          color: var(--c-primary);
        }
      }
    }
  }
}
//mobile
@media (max-width: $tablet-max) {
}
//desktop
@media (min-width: $desktop-min) {
}
</style>
