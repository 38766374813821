// General
export { default as EmptyWishlistIcon } from '~/static/icons/general/EmptyWishlistIcon.svg';
export { default as FullWishlistIcon } from '~/static/icons/general/FullWishlistIcon.svg';
export { default as EmptyWishlistProductIcon } from '~/static/icons/general/EmptyWishlistProductIcon.svg';
export { default as FullWishlistProductIcon } from '~/static/icons/general/FullWishlistProductIcon.svg';
export { default as AccountIcon } from '~/static/icons/general/AccountIcon.svg';
export { default as BonusIcon } from '~/static/icons/general/BonusIcon.svg';
export { default as CarrelloIcon } from '~/static/icons/general/CarrelloIcon.svg';
export { default as CasaUfficioIcon } from '~/static/icons/general/CasaUfficioIcon.svg';
export { default as EmailIcon } from '~/static/icons/general/EmailIcon.svg';
export { default as FacebookIcon } from '~/static/icons/general/FacebookIcon.svg';
export { default as FotografiaIcon } from '~/static/icons/general/FotografiaIcon.svg';
export { default as GrandiElettrodomesticiIcon } from '~/static/icons/general/GrandiElettrodomesticiIcon.svg';
export { default as PiccoliElettrodomesticiIcon } from '~/static/icons/general/PiccoliElettrodomesticiIcon.svg';
export { default as InformaticaIcon } from '~/static/icons/general/InformaticaIcon.svg';
export { default as InstagramIcon } from '~/static/icons/general/InstagramIcon.svg';
export { default as SpedizioniIcon } from '~/static/icons/general/SpedizioniIcon.svg';
export { default as TelIcon } from '~/static/icons/general/TelIcon.svg';
export { default as TvAudioVideoIcon } from '~/static/icons/general/TvAudioVideoIcon.svg';
export { default as TwitterIcon } from '~/static/icons/general/TwitterIcon.svg';
export { default as AlertIcon } from '~/static/icons/general/alert.svg';
