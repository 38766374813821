/* eslint-disable unicorn/consistent-destructuring */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'vue-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Locale } from 'vue-i18n';
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';

function isLocalized(route: Route, localeCodes: readonly Locale[]): boolean {
  let localeFound = false;
  localeCodes.forEach((localeCode) => {
    if (route?.path.match(new RegExp(`^(/${localeCode})`))) {
      localeFound = true;
    }
  });
  return localeFound;
}

function isHome(route: Route) {
  return route?.path === '/';
}

export default ({ app, route, redirect }: Context) => {
  const { i18n } = app;

  // old m1 urls redirects to /it
  if (!isHome(route) && !isLocalized(route, i18n?.localeCodes)) {
    if (route.fullPath.includes('.html')) {
      route.fullPath = route.fullPath.replace('.html', '');
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    redirect(301, `/it/${route.fullPath.replace(/^(\/)/, '')}`);
  }

  if (route.fullPath.slice(-1) === '/' && isLocalized(route, i18n?.localeCodes)) {
    redirect(301, `${route.fullPath.slice(0, -1)}`);
  }
  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });
  }
};
