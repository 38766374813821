<template>
  <div>
    <IconSprite />
    <CartSidebar />
    <LoginModal />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <TopBar class="both" :check-sticky="checkSticky" />
    <AppHeader :check-sticky="checkSticky" />
    <div id="layout-error">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible> <LogoCarousel /> </LazyHydrate>
    <LazyHydrate when-visible> <AppFooter /> </LazyHydrate>
    <LazyHydrate when-visible> <Copyright /> </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUser } from '@gemini-vsf/composables';
import AppHeader from '~/components/AppHeader.vue';
import TopBar from '~/components/TopBar.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import Copyright from '~/components/Footer/Copyright.vue';
import Notification from '~/components/Notification.vue';
import LogoCarousel from '~/components/LogoCarousel.vue';
import { useCart } from '~/composables';

export default defineComponent({
  name: 'ErrorLayout',

  components: {
    Copyright,
    LazyHydrate,
    TopBar,
    AppHeader,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar,
    LoginModal,
    Notification,
    IconSprite: () =>
      import(/* webpackPrefetch: true */ '~/components/General/IconSprite.vue'),
    LogoCarousel,
  },

  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart } = useCart();
    const flag = ref(false);

    const { loadConfiguration } = useGeminiConfiguration();

    onMounted(() => {
      loadUser();
      loadCart({
        customQuery: {
          cart: 'cartCustom',
        },
      });
    });

    useFetch(async () => {
      await loadConfiguration();
    });

    return {
      route,
      isAuthenticated,
      flag,
      user,
    };
  },
  data() {
    const checkSticky = false;

    return {
      checkSticky,
    };
  },
  mounted() {
    this.scroll();
  },
  unMounted() {
    this.removeScroll();
  },
  methods: {
    handleScroll() {
      const st = window.scrollY;
      this.checkSticky = st >= Number.parseInt('20', 10);
    },
    scroll() {
      window.addEventListener('scroll', this.handleScroll);
    },
    removeScroll() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
#layout-error {
  box-sizing: border-box;
  @media (max-width: $tablet-max) {
    margin: 190px auto auto;
  }
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
}
// Reset CSS
html {
  width: auto;
  @include for-mobile {
    overflow-x: hidden;
  }
}
body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: var(--c-link);
  &:hover {
    color: var(--c-link-hover);
  }
}
h1 {
  font-family: var(--font-family--primary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}
h2 {
  font-family: var(--font-family--primary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}
h3 {
  font-family: var(--font-family--primary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}
h4 {
  font-family: var(--font-family--primary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}
</style>
