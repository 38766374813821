<template>
  <div v-if="showWishlistButton" class="wishlist-button">
    <SfButton
      :aria-label="`${ariaLabel} ${title}`"
      class="sf-button--pure sf-product-card__wishlist-icon"
      :class="isInWishlist && 'on-wishlist'"
      data-testid="wishlist-button"
      @click="toggleIsInWishlist"
    >
      <div class="product-card-icon">
        <FullWishlistProductIcon v-if="isInWishlist" />
        <EmptyWishlistProductIcon v-else />
      </div>
    </SfButton>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

import {
  FullWishlistProductIcon,
  EmptyWishlistProductIcon,
} from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardWishListButton',
  components: { SfButton, FullWishlistProductIcon, EmptyWishlistProductIcon },
  props: {
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showWishlistButton: {
      type: Boolean,
      default: true,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    title: {
      type: String,
      default: '',
    },
    productName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { send: sendNotification } = useUiNotification();
    const translate = useI18n();
    const toggleIsInWishlist = () => {
      emit('clickWishlist', !props.isInWishlist);
      if (!props.isInWishlist) {
        sendNotification({
          id: Symbol(''),
          message: `${props.productName} , ${translate.t('added to wishlist')}`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Success',
        });
      } else {
        sendNotification({
          id: Symbol(''),
          message: `${props.productName} , ${translate.t(
            'removed from wishlist'
          )}`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Success',
        });
      }
    };
    const ariaLabel = () =>
      props.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';

    return { toggleIsInWishlist, ariaLabel };
  },
});
</script>

<style lang="scss" scoped>
.wishlist-button {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 6px;
  right: 20px;
  .sf-product-card__wishlist-icon {
    position: static;
    width: fit-content;
  }
}
</style>
