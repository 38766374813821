<template>
  <div class="privacy-policy">
    <SfCheckbox
      class="form__element form__action-privacy"
      name="privacy"
      :disabled="false"
      :required="true"
      hint-message=""
      :selected="privacyChecked"
      @change="(e) => handlePrivacyCheck(e)"
    >
      <template #checkmark>
        <span
          class="sf-checkbox__checkmark"
          :class="{ 'is-active': privacyChecked }"
        >
          <SfIcon
            :class="{ 'display-none': !privacyChecked }"
            icon="check"
            color="white"
          />
        </span>
      </template>
      <template #label>
        <span class="sf-checkbox__label">
          {{ `${$t(checkboxLabel)}` }}
          <SfLink class="privacy-link" :link="link" target="_blank">
            {{ linkName }}
          </SfLink>
          *
        </span>
      </template>
    </SfCheckbox>
    <div
      id="privacy-error-custom"
      class="sf-checkbox__message custom-message"
      :class="{ 'display-none': privacyChecked }"
    >
      {{ $t(errorMessage) }}
    </div>
  </div>
</template>

<script>
import { SfLink, SfCheckbox, SfIcon } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ConditionsAcceptance',
  components: {
    SfLink,
    SfCheckbox,
    SfIcon,
  },
  props: {
    checkboxLabel: {
      type: String,
      default: 'Accetto la',
    },
    link: {
      type: String,
      default: '/privacy',
    },
    linkName: {
      type: String,
      default: 'Privacy Policy',
    },
    errorMessage: {
      type: String,
      default: 'Devi accettare la privacy policy per procedere',
    },
  },
  setup(props, { emit }) {
    const privacyChecked = ref(false);
    const handlePrivacyCheck = (value) => {
      privacyChecked.value = value;
      emit('valueChange', privacyChecked.value);
    };

    return {
      privacyChecked,
      handlePrivacyCheck,
    };
  },
});
</script>

<style lang="scss">
.privacy-policy {
  margin-top: var(--spacer-base);

  .sf-checkbox__label {
    font-size: var(--font-size--4xs);
    font-family: var(--font-family--secondary);
    color: var(--c-grey-2);
  }
  .privacy-link {
    font-size: var(--font-size--4xs);
    font-family: var(--font-family--secondary-bold);
    color: var(--c-grey-2);
  }
  .sf-checkbox__message {
    font-size: var(--font-size--5xs);
    font-family: var(--font-family--secondary);
  }
}
</style>
