/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Cart, CartItem } from '@gemini-vsf/composables';
import { useCart } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';

export const useAdditionalServices = () => {
  const { error: cartError } = useCart();
  const { showCartError } = useAddToCart();
  const handleNotes = async (currentNotesObject: object, sku: string, addNotesFunction: any, selectedServices?: string[]) => {
    /* Save and object with product GRN as key and an array containing selected services GRNs. If no services
      have been selected, delete the current product key.  */
    if (selectedServices && selectedServices?.length > 0) {
      currentNotesObject[sku] = selectedServices;
      await addNotesFunction({
        note: JSON.stringify(currentNotesObject),
      });
      // eslint-disable-next-line no-prototype-builtins
    } else if (Object.keys(currentNotesObject).length > 0 && Object.keys(currentNotesObject).includes(sku)) {
      delete currentNotesObject[sku];
      await addNotesFunction({
        note: JSON.stringify(currentNotesObject),
      });
    }
  };

  const updateServices = async (currentCart: Cart, mainProductSku: string, notesObject: object, bulkUpdateFunction: any) => {
    const cartItems = Array.isArray(currentCart?.items) && currentCart?.items.length > 0 ? [...currentCart.items] : [];
    if (notesObject?.[mainProductSku] && cartItems.length > 0) {
      const currentItemAssociatedServices = cartItems.filter((cartItem) => {
        return notesObject?.[mainProductSku]?.includes(cartItem.product.sku);
      });
      if (currentItemAssociatedServices.length > 0) {
        await bulkUpdateFunction({ products: currentItemAssociatedServices });
      }
      /* bulkUpdateItemsQty exists in error interface */
      // @ts-ignore
      if (cartError.value.bulkUpdateItemsQty) {
        // @ts-ignore
        showCartError(cartError.value.bulkUpdateItemsQty);
      }
    }
  };

  return {
    handleNotes,
    updateServices,
  };
};
