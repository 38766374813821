import {
  CartItem,
  Product,
  useCart as useCartComposable,
  usePaymentProvider as usePaymentProviderComposable,
  useUser,
} from '@gemini-vsf/composables';
import { computed } from '@nuxtjs/composition-api';
import { customQuery } from '@gemini-vsf/api-client/lib/api';

interface UpdateItemQuantityInterface {
  product: CartItem;
  quantity: number;
}

interface SetBusinessDataInterface {
  businessData: {
    tax_code?: string;
    certified_email?: string;
    sdi_code?: string;
    fiscal_code?: string;
    company_name?: string;
  };
}

interface BulkUpdateItemsQtyInterface {
  products: CartItem[];
  quantityMap?: object;
  subtract?: boolean;
}
const useCart = () => {
  const {
    load: loadCart,
    cart,
    addItem: addItemComposable,
    addNoteOnCart: addNoteOnCartComposable,
    applyCoupon: applyCouponComposable,
    applyGiftCard: applyGiftCardComposable,
    removeCoupon: removeCouponComposable,
    removeGiftCard: removeGiftCardComposable,
    removeItem: removeItemComposable,
    bulkRemoveItems: bulkRemoveItemsComposable,
    updateItemQty: updateItemQtyComposable,
    bulkUpdateItemsQty: bulkUpdateItemsQtyComposable,
    setBusinessData: setBusinessDataComposable,
    clear: clearComposable,
    isInCart,
    loading,
    error,
  } = useCartComposable();

  const { save: setPaymentMethodOnCartComposable } = usePaymentProviderComposable();

  const { isAuthenticated, load: loadUser } = useUser();

  const cartTotalItems = computed(() => (cart?.value && cart.value?.total_quantity) || null);
  const cartItems = computed(() => (cart?.value && cart.value?.items.filter(Boolean)) || []);

  const setPaymentMethodOnCart = async (paymentMethod: any) => {
    await setPaymentMethodOnCartComposable({
      paymentMethod,
      customQuery: { setPaymentMethodOnCart: 'setPaymentMethodOnCartCustom' },
    });
  };

  const load = async () => {
    await loadUser();
    await loadCart({
      customQuery: isAuthenticated.value
        ? {
            customerCart: 'customerCartCustom',
          }
        : {
            cart: 'cartCustom',
          },
    });
  };

  const addItem = async (item: { product: Product; quantity: number }) => {
    await addItemComposable({
      ...item,
      customQuery: {
        addProductsToCart: 'addSimpleProductsToCartCustom',
      },
    });
  };

  const addNoteOnCart = async ({ note }) => {
    await addNoteOnCartComposable({
      note,
      customQuery: { setNotesToCart: 'setNotesToCartCustom' },
    });
  };

  const applyCoupon = async (couponCode: string) => {
    await applyCouponComposable({
      couponCode,
      // customQuery: {
      //   applyCouponToCart: 'applyCouponToCartCustom',
      // },
    });
  };

  const applyGiftCard = async (giftCardCode: string) => {
    await applyGiftCardComposable({
      giftCardCode,
      // customQuery: {
      //   applyGiftCardToCart: 'applyGiftCardToCartCustom',
      // },
    });
  };

  const removeCoupon = async (couponCode: string) => {
    await removeCouponComposable({
      couponCode,
      // customQuery: {
      //   removeCouponFromCart: 'removeCouponFromCart',
      // },
    });
  };

  const removeGiftCard = async (giftCardCode: string) => {
    await removeGiftCardComposable({
      giftCardCode,
      // customQuery: { removeGiftCardFromCart: 'removeGiftCardFromCartCustom' },
    });
  };

  const removeItem = async (product: CartItem) => {
    await removeItemComposable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      product: product.product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
  };

  const bulkRemoveItems = async ({ products }) => {
    await bulkRemoveItemsComposable({
      products,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
  };

  const updateItemQty = async ({ product, quantity }: UpdateItemQuantityInterface) => {
    await updateItemQtyComposable({
      product,
      quantity,
      customQuery: {
        updateCartItems: 'updateCartItemsCustom',
      },
    });
  };

  const bulkUpdateItemsQty = async ({ products, quantityMap, subtract }: BulkUpdateItemsQtyInterface) => {
    await bulkUpdateItemsQtyComposable({
      products,
      quantityMap,
      subtract,
      customQuery: {
        updateCartItems: 'updateCartItemsCustom',
      },
    });
  };

  const setBusinessData = async (businessData: SetBusinessDataInterface) => {
    await setBusinessDataComposable({
      ...businessData,
      customQuery: {
        setBusinessDataOnCart: 'setBusinessDataOnCartCustom',
      },
    });
  };

  const clear = async () => {
    await clearComposable();
  };

  return {
    ...useCartComposable(),
    load,
    cart,
    addItem,
    addNoteOnCart,
    applyCoupon,
    applyGiftCard,
    removeCoupon,
    removeGiftCard,
    removeItem,
    bulkRemoveItems,
    updateItemQty,
    bulkUpdateItemsQty,
    setBusinessData,
    clear,
    isInCart,
    setPaymentMethodOnCart,
    cartTotalItems,
    cartItems,
    loading,
    error,
  };
};

export default useCart;
