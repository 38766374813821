var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfModal',{directives:[{name:"e2e",rawName:"v-e2e",value:('newsletter-modal'),expression:"'newsletter-modal'"}],staticClass:"newsletter-modal",attrs:{"visible":_vm.isNewsletterModalOpen},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-bar",fn:function(){return [_c('SfBar',{staticClass:"sf-modal__bar smartphone-only",attrs:{"close":true,"title":_vm.$t(_vm.barTitle)},on:{"click:close":_vm.closeModal}})]},proxy:true}])},[_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[_c('div',{staticClass:"form"},[_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","id":"email","placeholder":_vm.$t('Your email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_vm._v(" "),(!errors[0] && !_vm.form.email && _vm.isValidEmail)?_c('p',{staticClass:"newsletter-form-custom-error"},[_vm._v("\n              "+_vm._s(_vm.$t('Invalid email'))+"\n            ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name","placeholder":_vm.$t('First Name')},model:{value:(_vm.form.FIRSTNAME),callback:function ($$v) {_vm.$set(_vm.form, "FIRSTNAME", $$v)},expression:"form.FIRSTNAME"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last-name","placeholder":_vm.$t('Last Name')},model:{value:(_vm.form.LASTNAME),callback:function ($$v) {_vm.$set(_vm.form, "LASTNAME", $$v)},expression:"form.LASTNAME"}})]}}],null,true)}),_vm._v(" "),_c('ConditionsAcceptance',{directives:[{name:"e2e",rawName:"v-e2e",value:('privacy'),expression:"'privacy'"}],staticClass:"form__element",on:{"valueChange":_vm.changePrivacy}}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),(_vm.error.register)?_c('div',{staticClass:"newsletter-form-custom-error"},[_vm._v("\n            "+_vm._s(_vm.$t(_vm.error.register))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.brevoLoading)?_c('SfLoader',{attrs:{"loading":_vm.brevoLoading}}):_c('CustomButton',{attrs:{"disabled":invalid || !_vm.privacyChecked},on:{"click":_vm.handleForm}},[_vm._v("\n            "+_vm._s(_vm.$t('Register'))+"\n          ")])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }