<template>
  <div class="product-card-details">
    <div class="product-card-brand">{{ brand }}</div>
    <div class="product-card-sku-description">
      <div class="product-card-sku">{{ `${title}` }}</div>
      <div class="product-card-description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardDetails',
  components: {},
  props: {
    brand: {
      type: String,
      default: 'Placeholder',
    },
    sku: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.product-card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 0.9375rem auto;
  font-family: var(--font-family--secondary);

  .product-card-brand {
    color: var(--c-grey-2);
    text-transform: uppercase;
    margin-bottom: 0.625rem;
  }
  .product-card-sku,
  .product-card-title {
    font-weight: bold;
  }
  .product-card-sku-description {
    min-height: 2.125rem;
    text-transform: lowercase;
  }
  .product-card-sku,
  .product-card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

//mobile
@media (max-width: $mobile-max) {
  .product-card-details {
    font-size: var(--font-size--4xs);
    line-height: var(--font-size--2xs);
    .product-card-brand {
      font-size: var(--font-size--4xs);
      line-height: var(--font-size--2xs);
    }
    .product-card-sku-description {
      min-height: fit-content;
    }
    .product-card-sku,
    .product-card-description {
      max-height: 36px;
    }
  }
}
//desktop
@media (min-width: $tablet-min) {
  .product-card-details {
    font-size: var(--font-size--3xs);
    line-height: var(--font-size--xs);
    .product-card-brand {
      font-size: var(--font-size--4xs);
      line-height: var(--font-size--xs);
    }
  }
}
</style>
