





















import { defineComponent, ref } from '@nuxtjs/composition-api';
import CustomButton from '~/components/CustomButton.vue';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { Product } from '@gemini-vsf/composables';
import { useGtm, useCart, useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAdditionalServices } from '~/helpers/cart/additionalServices';

export default defineComponent({
  name: 'ProductCardAddToCartButton',
  components: { CustomButton },
  props: {
    showAddToCart: {
      type: Boolean,
      default: false,
    },
    productEntity: {
      type: Object,
      default: () => {},
    },
    canAddToCart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { addItemToCart } = useAddToCart();
    const { actionCartGtmPush } = useGtm();
    const { error: cartError, cart, bulkUpdateItemsQty } = useCart();
    const { updateServices } = useAdditionalServices();
    const gtmIndex = ref(0);
    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();

    const handleAddToCart = async () => {
      if (props.canAddToCart) {
        await addItemToCart({
          product: props.productEntity as Product,
          quantity: 1,
        });
        if (!cartError.value.addItem) {
          const notesObject =
            cart.value?.notes &&
            cart.value?.notes !== '' &&
            cart.value?.notes !== '{}'
              ? JSON.parse(cart.value.notes)
              : {};
          await updateServices(
            cart.value,
            props.productEntity.sku,
            notesObject,
            bulkUpdateItemsQty
          );
          sendNotification({
            id: Symbol(''),
            message: `${props.productEntity.name} ${trans.t('added to cart')}`,
            type: 'success',
            icon: 'check',
            persist: false,
            title: 'Success',
          });
        } else {
          sendNotification({
            id: Symbol('cartError'),
            message: `${cartError.value.addItem}`,
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Error',
          });
        }
        actionCartGtmPush(
          props.productEntity,
          1,
          'add_to_cart',
          gtmIndex.value
        );
        gtmIndex.value += 1;
      }
    };

    return { handleAddToCart };
  },
});
