<template>
  <div class="item-content col-form-newsletter">
    <p class="subtitle">
      {{ subtitle }}
    </p>
    <ActionInput
      :placeholder="$t('Insert your email')"
      :button-text="$t('Subscribe')"
      type="text"
      v-model="inputValue"
      @actionClick="
        () => {
          handleFooterNewsletter();
        }
      "
      @keyup.enter="
        () => {
          handleFooterNewsletter();
        }
      "
    />
    <NewsletterModal :email="inputValue" />
  </div>
</template>

<script>
import ActionInput from '@/components/ActionInput.vue';
import { useUiState } from '~/composables';
import NewsletterModal from '~/components/NewsletterModal.vue';
import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'NewsletterSubscribe',
  components: {
    ActionInput,
    NewsletterModal,
  },
  props: {
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { toggleNewsletterModal } = useUiState();
    const inputValue = ref('');

    const handleFooterNewsletter = () => {
      toggleNewsletterModal();
    };

    return {
      handleFooterNewsletter,
      inputValue,
    };
  },
  data() {
    return {
      email: '',
      emailBlur: true,
    };
  },
  methods: {
    submit() {},
    validEmail(email) {
      const regex = /(.+)@(.+){2,}\.(.+){2,}/;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return regex.test(email.toLowerCase());
    },
  },
};
</script>

<style lang="scss">
.col-form-newsletter {
  .subtitle {
    font-size: var(--font-size--3xs);
    line-height: var(--font-size--xs);
    color: var(--c-grey-3);
    font-family: var(--font-family--secondary);
    margin-bottom: 20px;
  }
  .sf-action-input {
    z-index: 5;
    background-color: unset;
    .sf-input__wrapper {
      border-radius: 32px 0 0 32px;
      background: var(--c-grey-6);
      color: var(--c-grey-3);

      input {
        border-radius: 32px 0 0 32px;
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--xs);
        &:hover,
        &:focus {
          background: var(--c-white);
        }
      }
    }
  }
}

//mobile
@media (max-width: $tablet-max) {
}
//desktop
@media (min-width: $desktop-min) {
}
</style>
