import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f04537e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _42a0bda8 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _97e42006 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _19235ef0 = () => interopDefault(import('../pages/CheckoutError.vue' /* webpackChunkName: "" */))
const _7069626c = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _6f2f1c30 = () => interopDefault(import('../pages/MyAccount/AddressesDetails.vue' /* webpackChunkName: "pages/MyAccount/AddressesDetails" */))
const _03b110f5 = () => interopDefault(import('../pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "pages/MyAccount/MyNewsletter" */))
const _a43397c6 = () => interopDefault(import('../pages/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/MyAccount/MyProfile" */))
const _9222d5fe = () => interopDefault(import('../pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "pages/MyAccount/MyWishlist" */))
const _42139b54 = () => interopDefault(import('../pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "pages/MyAccount/OrderHistory" */))
const _0b5f7600 = () => interopDefault(import('../pages/MyAccount/_MyReviews.vue' /* webpackChunkName: "pages/MyAccount/_MyReviews" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _8f04537e,
    meta: {"hideNavigation":true},
    name: "home___en"
  }, {
    path: "/it",
    component: _8f04537e,
    meta: {"hideNavigation":true},
    name: "home___it"
  }, {
    path: "/en/Cart",
    component: _544757fc,
    name: "Cart___en"
  }, {
    path: "/en/Category",
    component: _0b2841e0,
    name: "Category___en"
  }, {
    path: "/en/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___en"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___en"
    }]
  }, {
    path: "/en/Checkout",
    component: _48ef6e28,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___en"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___en"
    }]
  }, {
    path: "/en/checkout-error",
    component: _19235ef0,
    meta: {"simpleHeader":true},
    name: "checkout-error___en"
  }, {
    path: "/en/CheckoutError",
    component: _19235ef0,
    name: "CheckoutError___en"
  }, {
    path: "/en/contact-us",
    component: _7069626c,
    name: "contact-us___en"
  }, {
    path: "/en/ContactUs",
    component: _7069626c,
    name: "ContactUs___en"
  }, {
    path: "/en/Home",
    component: _8f04537e,
    name: "Home___en"
  }, {
    path: "/en/MyAccount",
    component: _10189cef,
    name: "MyAccount___en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___en"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___en"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___en"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___en"
    }]
  }, {
    path: "/en/Page",
    component: _08b2ebf1,
    name: "Page___en"
  }, {
    path: "/en/Product",
    component: _4bba9d7d,
    name: "Product___en"
  }, {
    path: "/en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___en"
  }, {
    path: "/en/Route",
    component: _34cba952,
    name: "Route___en"
  }, {
    path: "/it/Cart",
    component: _544757fc,
    name: "Cart___it"
  }, {
    path: "/it/Category",
    component: _0b2841e0,
    name: "Category___it"
  }, {
    path: "/it/checkout",
    component: _48ef6e28,
    meta: {"simpleHeader":true},
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _26e24f74,
      meta: {"simpleHeader":true},
      name: "billing___it"
    }, {
      path: "payment",
      component: _74dcbd02,
      meta: {"simpleHeader":true},
      name: "payment___it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      meta: {"simpleHeader":true},
      name: "shipping___it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      meta: {"simpleHeader":true},
      name: "thank-you___it"
    }, {
      path: "user-account",
      component: _7855b4db,
      meta: {"simpleHeader":true},
      name: "user-account___it"
    }]
  }, {
    path: "/it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it"
    }]
  }, {
    path: "/it/checkout-error",
    component: _19235ef0,
    meta: {"simpleHeader":true},
    name: "checkout-error___it"
  }, {
    path: "/it/CheckoutError",
    component: _19235ef0,
    name: "CheckoutError___it"
  }, {
    path: "/it/contact-us",
    component: _7069626c,
    name: "contact-us___it"
  }, {
    path: "/it/ContactUs",
    component: _7069626c,
    name: "ContactUs___it"
  }, {
    path: "/it/Home",
    component: _8f04537e,
    name: "Home___it"
  }, {
    path: "/it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___it"
    }, {
      path: "MyNewsletter",
      component: _03b110f5,
      name: "MyAccount-MyNewsletter___it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___it"
    }, {
      path: "MyWishlist",
      component: _9222d5fe,
      name: "MyAccount-MyWishlist___it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___it"
    }, {
      path: ":MyReviews?",
      component: _0b5f7600,
      name: "MyAccount-MyReviews___it"
    }]
  }, {
    path: "/it/Page",
    component: _08b2ebf1,
    name: "Page___it"
  }, {
    path: "/it/Product",
    component: _4bba9d7d,
    name: "Product___it"
  }, {
    path: "/it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it"
  }, {
    path: "/it/Route",
    component: _34cba952,
    name: "Route___it"
  }, {
    path: "/en/c/:categoryId",
    component: _0b2841e0,
    name: "category___en"
  }, {
    path: "/en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___en"
  }, {
    path: "/en/p/:productId",
    component: _4bba9d7d,
    name: "product___en"
  }, {
    path: "/it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it"
  }, {
    path: "/it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it"
  }, {
    path: "/it/p/:productId",
    component: _4bba9d7d,
    name: "product___it"
  }, {
    path: "/en/:path+",
    component: _34cba952,
    name: "route-resolver___en"
  }, {
    path: "/it/:path+",
    component: _34cba952,
    name: "route-resolver___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
