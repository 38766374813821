<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.01"
    height="28.012"
    viewBox="0 0 24.01 28.012"
  >
    <g id="delete" transform="translate(-4.5 -2.25)">
      <path
        id="track_1889"
        data-name="track 1889"
        d="M13.5,13.5h2V25.505h-2Z"
        transform="translate(-0.997 -1.246)"
        fill="var(--c-primary)"
      />
      <path
        id="track_1890"
        data-name="track 1890"
        d="M20.25,13.5h2V25.505h-2Z"
        transform="translate(-1.744 -1.246)"
        fill="var(--c-primary)"
      />
      <path
        id="track_1891"
        data-name="track 1891"
        d="M4.5,6.75v2h2V28.759a2,2,0,0,0,2,2H24.508a2,2,0,0,0,2-2V8.751h2v-2Zm4,22.009V8.751H24.508V28.759Z"
        transform="translate(0 -0.498)"
        fill="var(--c-primary)"
      />
      <path
        id="track_1892"
        data-name="track 1892"
        d="M13.5,2.25h8v2h-8Z"
        transform="translate(-0.997)"
        fill="var(--c-primary)"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TrashIcon',
});
</script>

<style lang="scss" scoped></style>
