<template>
  <div class="product-card">
    <div
      class="product-card-top"
      @mouseenter="toggleAddToCart"
      @mouseleave="toggleAddToCart"
    >
      <nuxt-link :to="fixUrlForCurrentLocale(productUrl)">
        <ProductCardImage
          :image="productImage"
          :show-add-to-cart="addToCartIsVisible"
          image-tag="nuxt-img"
          :image-alt="productEntity.name || productEntity.uid"
          :nuxt-img-config="{
            fit: 'cover',
          }"
        />
      </nuxt-link>
      <ProductCardAddToCartButton
        class="desktop-only"
        :show-add-to-cart="addToCartIsVisible"
        :product-entity="productEntity"
        :can-add-to-cart="canAddToCart"
      />
    </div>
    <div class="product-card-bottom">
      <nuxt-link :to="fixUrlForCurrentLocale(productUrl)">
        <ProductCardDetails
          :sku="productEntity.merchant_sku"
          :title="productEntity.name"
          :brand="productBrand"
          description=""
          :show-add-to-cart="addToCartIsVisible"
        />
      </nuxt-link>
      <!--      <span v-if="!canAddToCart"> -->
      <!--        <strong class="product-card-bottom__not-available">{{ -->
      <!--          $t('Prodotto non disponibile') -->
      <!--        }}</strong> -->
      <!--      </span> -->
      <ProductCardPrice
        :regular-price="$fc(regularPrice)"
        :special-price="specialPrice && $fc(specialPrice)"
      />
    </div>

    <ProductCardWishListButton
      @clickWishlist="addToWishlist()"
      :product-name="productEntity.name"
      :is-in-wishlist="isInWishlist"
    />
    <div class="product-card__badge__wrapper">
      <SfBadge>
        <span> {{ productBadgeLabel }} </span>
      </SfBadge>
    </div>
  </div>
</template>

<script>
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';
import { SfBadge } from '@storefront-ui/vue';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '~/helpers/mobileObserver';
import ProductCardImage from '~/components/ProductCard/ProductCardImage.vue';
import ProductCardWishListButton from '~/components/ProductCard/ProductCardWishListButton.vue';
import ProductCardAddToCartButton from '~/components/ProductCard/ProductCardAddToCartButton.vue';
import ProductCardDetails from '~/components/ProductCard/ProductCardDetails.vue';
import ProductCardPrice from '~/components/ProductCard/ProductCardPrice.vue';
import { ref } from '@nuxtjs/composition-api';
import { productGetters } from '@gemini-vsf/composables';
import { useGtm, useUrlHelpers, useWishlist, useCart } from '~/composables';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default {
  name: 'SfProductCardCustom',
  components: {
    ProductCardImage,
    ProductCardWishListButton,
    ProductCardAddToCartButton,
    SfBadge,
    ProductCardDetails,
    ProductCardPrice,
  },
  props: {
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    showWishlistButton: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: true,
    },
    productEntity: {
      type: Object,
      default: () => {},
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      isAddingToCart: false,
      productPlaceholder,
    };
  },
  computed: {
    ...mapMobileObserver(),
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    canAddToCart() {
      if (this.isCartLoading?.value) {
        return false;
      }
      if (this.productEntity.is_virtual) {
        return true;
      }
      if (!this.regularPrice || this.regularPrice === 0) {
        return false;
      }
      const productQty =
        this.cart?.items?.filter((item) => {
          return item?.product?.uid === this.productEntity.uid;
        })[0]?.quantity || 0;

      const inStock = this.productEntity.stock_status === 'IN_STOCK' || false;
      const stockLeft =
        this.productEntity.only_x_left_in_stock === null
          ? false
          : productQty < this.productEntity.only_x_left_in_stock;
      return inStock && stockLeft;
    },
    ariaLabel() {
      return this.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';
    },
    wishlistIconClasses() {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      return `${defaultClass} ${this.isInWishlist ? 'on-wishlist' : ''}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
  },
  setup(props, { emit }) {
    const { buildProductUrl } = useUrlHelpers();
    const { toggleWishlist } = useWishlist();
    const { addToWishlistGtmPush } = useGtm();
    const { cart, isCartLoading } = useCart();

    const productBadgeLabel =
      props.productEntity?.condizione_prodotto_attribute?.label ||
      'prodotto nuovo';

    const productBrand =
      props.productEntity?.manufacturer_attribute?.label || 'PLACEHOLDER';

    const regularPrice = productGetters.getPrice(props.productEntity).regular;

    const specialPrice = productGetters.getPrice(props.productEntity).special;
    const productImage =
      productGetters.getProductThumbnailImage(props.productEntity) ||
      '/assets/product_placeholder.svg';

    const productUrl = buildProductUrl(props.productEntity);
    const addToCartIsVisible = ref(false);

    const toggleAddToCart = () => {
      addToCartIsVisible.value = !addToCartIsVisible.value;
    };

    const addToWishlist = () => {
      emit('clickWishlist');
      if (!props.isInWishlist) {
        addToWishlistGtmPush(props.productEntity);
      }
    };

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      addToCartIsVisible,
      addToWishlist,
      regularPrice,
      specialPrice,
      productImage,
      productBadgeLabel,
      productUrl,
      productBrand,
      cart,
      isCartLoading,
      buildProductUrl,
      toggleWishlist,
      toggleAddToCart,
      fixUrlForCurrentLocale,
    };
  },
  beforeUnmount() {
    unMapMobileObserver();
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  max-width: 20.375rem;
  height: 35.125rem;
  display: grid;
  position: relative;
  grid-template-rows: 72.5% 27.5%;
  .sf-badge {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: var(--font-size--5xs);
    width: fit-content;
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 1;
    height: auto;
    padding: 5px;
    // make border radius only on top right and bottom right
    border-radius: 0 3px 3px 0;
  }
  .product-card-bottom {
    position: relative;
    display: grid;
    .product-card-details {
      margin-left: var(--spacer-xs);
      margin-right: var(--spacer-xs);
    }
    .product-card-price-container {
      margin-top: 0.625rem;
      margin-left: var(--spacer-xs);
      margin-right: var(--spacer-xs);
      @media (max-width: $desktop-min) {
        margin-top: 0;
      }

      .sf-price {
        justify-content: flex-end;
      }
    }
    &__not-available {
      font-size: var(--font-size--3xs);
      line-height: var(--font-size--xs);
      font-family: var(--font-family--secondary);
      padding-left: 16px;
      color: #d41025;
    }
  }
}

@media (min-width: $desktop-xl-min) and (max-width: 1700px),
  (min-width: $desktop-min) and (max-width: 1280px) {
  .product-card {
    width: 16.25rem;
    max-width: 16.25rem;
    .product-card-top {
      width: inherit;
    }
  }
}

@media (max-width: 425px) {
  .product-card {
    height: 25.125rem;
    //width: 8.25rem;
    //max-width: 8.25rem;
    .product-card-top {
      width: inherit;
    }
  }
}

//desktop
@media (min-width: $desktop-min) {
  .product-card {
    .product-card-bottom {
      .product-card-details {
        margin-left: var(--spacer-xs);
        margin-right: var(--spacer-xs);
      }
      .product-card-price-container {
        margin-left: var(--spacer-xs);
        margin-right: var(--spacer-xs);
      }
    }
  }
}
</style>
