/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios, { AxiosResponse } from 'axios';

interface ContactForm {
  FIRSTNAME: string;
  LASTNAME: string;
  EMAIL: string;
  BONUSCODE?: string;
  PHONE: string;
  PRODUCT?: string;
  AMOUNT?: number;
  MESSAGE?: string;
}

const useBrevoForm = () => {
  const response: Ref<AxiosResponse> = ref(null);
  const success: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<any> = ref(null);

  const sendFormWithBrevo = async (data: ContactForm, type: string) => {
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      let mailSubject: string;
      let mailContent: string;
      const populateMailData = () => {
        switch (type) {
          case 'teacherBonus': {
            mailSubject = 'Applicazione acquisto carta docente';
            mailContent = `<html><head><style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;}td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}</style></head><body><h2>Richiesta applicazione carta docente</h2><p>Hai ricevuto questa mail perchè un cliente ha fatto richiesta di pagamento con carta docente</p><p>Di seguito l'anagrafica del cliente e i dati utili all'acquisto:<table><tr><th>Nome</th><th>Cognome</th> <th>Email</th><th>Codice</th><th>Telefono</th><th>Codice prodotto</th><th>Fondi applicati</th></tr><tr><td>${data.FIRSTNAME}</td><td>${data.LASTNAME}</td><td>${data.EMAIL}</td><td>${data.BONUSCODE}</td><td>${data.PHONE}</td><td>${data.PRODUCT}</td><td>${data.AMOUNT}</td></tr></table></body></html>`;
            break;
          }
          case 'contactUs': {
            mailSubject = 'Richiesta di contatto';
            mailContent = `<html><head><style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;}td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}</style></head><body><h2>Richiesta di contatto</h2><p>Hai ricevuto questa mail perchè un utente ha compilato il form di contatto</p><p>Di seguito i dati di contatto inseriti:<table><tr><th>Nome</th><th>Cognome</th><th>Email</th><th>Telefono</th><th>Message</th></tr><tr><td>${data.FIRSTNAME}</td><td>${data.LASTNAME}</td><td>${data.EMAIL}</td><td>${data.PHONE}</td><td>${data.MESSAGE}</td></tr></table></body></html>`;
            break;
          }
          default: {
            mailSubject = 'Form sito';
            mailContent = `<html><head><style>table {font-family: arial, sans-serif;border-collapse: collapse;width: 100%;}td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;}tr:nth-child(even) {background-color: #dddddd;}</style></head><body><h2>Form di contatto</h2><p>Hai ricevuto questa mail perchè un utente ha compilato un form</p><p>Di seguito i dati inseriti:<table><tr><th>Nome</th><th>Cognome</th><th>Email</th><th>Telefono</th><th>Message</th></tr><tr><td>${data.FIRSTNAME}</td><td>${data.LASTNAME}</td><td>${data.EMAIL}</td><td>${data.PHONE}</td><td>${data.MESSAGE}</td></tr></table></body></html>`;
          }
        }
      };
      populateMailData();
      const requestData = {
        sender: {
          name: `${data.LASTNAME} ${data.FIRSTNAME}`,
          email: data.EMAIL,
        },
        to: [
          {
            email: 'assistenza-clienti@bestdigit.it',
            name: 'BestDigit Assistenza',
          },
        ],
        subject: mailSubject,
        htmlContent: mailContent,
      };
      const sendFormResponse = await axios({
        method: 'get',
        url: '/api/brevo/sendForm',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { userData: requestData },
      });
      response.value = sendFormResponse.data.data;
      success.value = true;
      if (sendFormResponse?.data?.error) {
        error.value = sendFormResponse.data.error;
        success.value = false;
      }
    } catch (e) {
      error.value = e;
    }
    loading.value = false;
  };

  return {
    sendFormWithBrevo,
    response,
    success,
    loading,
    error,
  };
};

export default useBrevoForm;
