<template>
  <div
    class="product-card-image"
    :class="showAddToCart && 'add-to-cart-visible'"
  >
    <div class="product-card-image__wrapper">
      <nuxt-img
        class="product-card-image__img"
        :src="image"
        :alt="imageAlt"
        :width="imageWidth"
        :height="imageHeight"
        placeholder="/assets/product_placeholder.svg"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardImage',
  props: {
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [String, Number],
      default: 100,
    },
    imageHeight: {
      type: [String, Number],
      default: -1,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    showAddToCart: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
.product-card-image {
  width: 100%;
  height: 95%;
  background: var(--c-white) 0 0% no-repeat padding-box;
  box-shadow: 0 10px 35px #0000001a;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.add-to-cart-visible {
    .sf-image--wrapper {
      transition: all ease-in 200ms;
      transform: scale(0.9);
    }
  }
  &__wrapper {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in 200ms;
    transform: scale(0.8);
  }
  &__img {
    width: 100%;
    margin: 0;
    outline: none;
    object-fit: contain;
    max-height: 21.875rem;
    max-width: 17.5rem;
  }
}
</style>
