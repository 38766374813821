<template>
  <component
    :is="link ? 'SfLink' : 'button'"
    class="custom-button"
    :class="[
      primary ? 'primary' : 'secondary',
      disabled && 'disabled',
      rotateArrow && 'rotate-arrow-ne-e',
      widenArrow && 'widen-arrow',
      greyBackground && 'grey-background',
      greyHover && 'grey-hover',
    ]"
    :aria-disabled="disabled"
    :link="
      link !== '#' ? fixUrlForCurrentLocale(link) : fixUrlForCurrentLocale('/#')
    "
    :target="target"
    @mouseenter.native="changeCurrentIconColor(hoverIconColor)"
    @mouseleave.native="changeCurrentIconColor(iconColor)"
    @click="$emit('click')"
  >
    <SfIcon
      v-if="Object.keys(icon).length > 0 && iconPosition === 'left'"
      :icon="icon"
      :color="currentIconColor && currentIconColor"
      :size="iconSize && iconSize"
    />
    <slot />
    <SfIcon
      v-if="Object.keys(icon).length > 0 && iconPosition === 'right'"
      :icon="icon"
      :color="currentIconColor && currentIconColor"
      :size="iconSize && iconSize"
    />
  </component>
</template>

<script>
import { SfLink, SfIcon } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'CustomButton',
  components: {
    SfLink,
    SfIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    icon: {
      type: [String, Object],
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    iconColor: {
      type: String,
      default: '',
    },
    hoverIconColor: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '',
    },
    rotateArrow: {
      type: Boolean,
      default: false,
    },
    widenArrow: {
      type: Boolean,
      default: false,
    },
    greyBackground: {
      type: Boolean,
      default: false,
    },
    greyHover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentIconColor = ref(props.iconColor);
    const { fixUrlForCurrentLocale } = useCurrentLocale();

    const changeCurrentIconColor = (newColor) => {
      if (props.hoverIconColor && newColor) {
        currentIconColor.value = newColor;
      }
    };

    return {
      currentIconColor,
      changeCurrentIconColor,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-button,
.sf-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 5px;
  background: var(--background-primary-gradient);
  border-radius: 32px;
  opacity: 1;
  letter-spacing: 1.5px;
  color: var(--c-white);
  text-transform: uppercase;
  font-family: var(--font-family--primary);
  border: none;
  padding: 15px 40px;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size--4xs);
  white-space: nowrap;
  &:hover {
    background: var(--c-grey-8) 0 0 no-repeat padding-box;
    cursor: pointer;
  }
  &.secondary {
    color: var(--c-primary);
    border: 1px solid var(--c-primary);
    background: var(--c-white);
    &:hover {
      background: var(--background-primary-gradient-inverted);
      color: var(--c-white);
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  &.rotate-arrow-ne-e {
    .sf-icon {
      transform: rotate(-45deg);
      transition: all ease-in 200ms;
    }
    &:hover {
      .sf-icon {
        transform: rotate(0deg);
        transition: all ease-in 200ms;
      }
    }
  }
  &.widen-arrow {
    .sf-icon {
      width: 28px;
    }
  }
  &.grey-background {
    background: var(--c-grey-2);
  }
  &.grey-hover {
    &:hover {
      background: var(--c-grey-8);
      border: none;
    }
  }
}
</style>

<style lang="scss">
.custom-button,
.sf-link {
  &.widen-arrow {
    .sf-icon {
      justify-content: flex-start;
      &-path {
        transition: all ease-in 200ms;
        width: 18px;
      }
    }
    &:hover {
      .sf-icon {
        &-path {
          width: 28px;
        }
      }
    }
  }
}
</style>
