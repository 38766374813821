import { useUrlResolver as urlResolver } from '@gemini-vsf/composables';
import { useRoute } from '@nuxtjs/composition-api';
import { Route } from '@gemini-vsf/api-client';
import { ComputedProperty } from '@vue-storefront/core';

interface UseUrlResolverReturn {
  path: string;
  search: () => Promise<void>;
  result: ComputedProperty<Route>;
  loading: ComputedProperty<boolean>;
}

/**
 * Encodes the last segment of the route path if it contains a forward slash (%2F).
 * This function is used to handle product URLs that contain encoded forward slashes.
 * And minimize the risk of encoding the wrong paths, for example, collections with forward slashes in the slug.
 *
 * https://hevelop.atlassian.net/browse/BDLTS-216
 *
 * @param {string} routePath - The route path to be processed - example: /it/apple-iphone-15-pro-max-256gb-titanio-blu-best-espn-mu7a3ql%2Fa
 * @returns {string | null} - The encoded last segment of the route path if it contains a forward slash, otherwise null.
 */
const getEncodedPathForProductWithForwardSlash = (routePath: string): string | null => {
  if (!routePath) return null;
  try {
    const splitPath = routePath.split('/');
    // first segment is always the locale, and routePath always starts with /,
    // so we are interested in routes with length 3
    if (splitPath?.length === 3) {
      // since this is a product URL, the last segment is the product slug
      const lastSegment = splitPath[2];
      // Return the last segment if it contains '%2F'
      return lastSegment.includes('%2F') ? lastSegment : null;
    }
  } catch (error) {
    console.error('useUrlResolver ~ getEncodedPathForProductWithForwardSlash ~ error:', error);
  }
  return null;
};

export const useUrlResolver = (): UseUrlResolverReturn => {
  const route = useRoute();
  const {
    params: { path },
    path: routePath,
  } = route.value;

  const encodedPath = getEncodedPathForProductWithForwardSlash(routePath);

  const { search, result, loading } = urlResolver(`router:${path}`);

  return {
    path,
    search: async () => {
      await search({ url: encodedPath || path });
    },
    result,
    loading,
  };
};
