<template>
  <div class="product-card-price-container">
    <SfPrice
      class="sf-product-card__price"
      :class="regularPrice && specialPrice ? 'invert-price-position' : ''"
      :regular="regularPrice"
      :special="specialPrice"
    />
    <div
      v-if="discountPercentage"
      class="product-card-price-discount-percentage"
    >
      <SfBadge> {{ discountPercentage }}% </SfBadge>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfPrice, SfBadge } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCardPrice',
  components: { SfPrice, SfBadge },
  props: {
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const getFloat = (price) =>
      Number.parseFloat(price?.replaceAll('.', '')?.replaceAll(',', '.'));

    const discountPercentage = computed(() =>
      props.regularPrice && props.specialPrice
        ? Math.round(
            100 -
              (getFloat(props.specialPrice) / getFloat(props.regularPrice)) *
                100
          )
        : undefined
    );

    return {
      discountPercentage,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-card-price-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: var(--font-size--xs);
  margin-left: var(--spacer-sm);
  margin-right: var(--spacer-sm);
  .sf-price {
    gap: var(--spacer-sm);
    &__regular,
    &__special {
      color: var(--c-primary);
      font-weight: bold;
    }
    &.invert-price-position {
      flex-direction: row-reverse;
      .sf-price__old {
        color: var(--c-grey-2);
        font-weight: 400;
      }
    }
  }
  .product-card-price-discount-percentage {
    .sf-badge {
      font-weight: bold;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
//mobile
@media (max-width: $mobile-max) {
  .product-card-price-container {
    font-size: var(--font-size--5xs);
    .sf-price {
      &__regular,
      &__special {
        font-size: var(--font-size--3xs);
        line-height: var(--font-size--2xs);
      }
      &.invert-price-position {
        flex-direction: column;
        margin: 0;
        grid-gap: 5px;
        align-items: flex-start;
        .sf-price__old {
          font-size: var(--font-size--3xs);
          line-height: var(--font-size--2xs);
        }
      }
    }
    .product-card-price-discount-percentage {
      .sf-badge {
        font-weight: bold;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size--4xs);
        line-height: var(--font-size--3xs);
      }
    }
  }
}
//desktop
@media (min-width: $tablet-min) {
  .product-card-price-container {
    .sf-price {
      &__regular,
      &__special {
        font-size: var(--font-size--xs);
        line-height: var(--font-size--s);
      }
      &.invert-price-position {
        .sf-price__old {
          font-size: var(--font-size--xs);
          line-height: var(--font-size--s);
        }
      }
    }
    .product-card-price-discount-percentage {
      .sf-badge {
        font-size: var(--font-size--xs);
        line-height: var(--font-size--s);
      }
    }
  }
}
</style>
