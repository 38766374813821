export { default as ArrowLeft } from './arrow_left.svg';
export { default as ArrowRight } from './arrow_right.svg';
export { default as ArrowRightDark } from './arrow_right_dark.svg';
export { default as ArrowLeftDark } from './arrow_left_dark.svg';
export { default as Truck } from './truck.svg';
export { default as HamburgerWhite } from './hamburger_white.svg';
export { default as FacebookIcon } from './social/facebook.svg';
export { default as LinkedinIcon } from './social/linkedin.svg';
export { default as PinterestIcon } from './social/pinterest.svg';
export { default as TelegramIcon } from './social/telegram.svg';
export { default as TwitterIcon } from './social/twitter.svg';
export { default as Cross } from './cross.svg';
export { default as BesDigit } from './best_digit.svg';
