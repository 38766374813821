










































































import { SfOverlay, SfHeader } from '@storefront-ui/vue';
import {
  computed,
  ref,
  defineComponent,
  useRoute,
  useFetch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderNavigationMobile from '~/components/Header/Navigation/HeaderNavigationMobile.vue';
import { useMenu } from '@gemini-vsf/composables';
import SearchBarCustom from '~/components/SearchBarCustom.vue';
import SearchResults from '~/components/Header/SearchBar/SearchResults.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppHeader: any = {
  name: 'AppHeader',
  components: {
    SearchBarCustom,
    HeaderNavigation,
    HeaderNavigationMobile,
    SfHeader,
    SfOverlay,
    SearchResults,
  },
  props: {
    checkSticky: {
      type: Boolean,
      default: false,
    },
    simpleHeader: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();

    const { menu, search: searchMenu } = useMenu('common');

    const isSearchOpen = ref(false);
    const productSearchResults = ref(null);
    const categoriesResult = ref(null);

    const loadingSearchResults = ref(false);

    const isHome = computed(() =>
      route.value?.name ? Boolean(route.value.name.includes('home')) : undefined
    );

    const loadingCategoryResults = ref(false);
    const categoryFilter = ref([]);

    const currentPage = ref(1);
    const nextPage = () => {
      currentPage.value += 1;
    };

    useFetch(async () => {
      await searchMenu({ code: 'mainmenu' });
    });

    return {
      currentPage,
      menu,
      isSearchOpen,
      productSearchResults,
      categoriesResult,
      loadingSearchResults,
      categoryFilter,
      loadingCategoryResults,
      isHome,
      nextPage,
    };
  },
};
export default defineComponent(AppHeader);
